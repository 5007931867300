// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { generatePostCall } from '../api_calls/generatePostCall';
import { API_ENDPOINT_NAME } from '../utils/constants';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBm8918p1AoN284kl-G0lg_k8fkNvYMQEg',
  authDomain: 'easebite-53fcd.firebaseapp.com',
  projectId: 'easebite-53fcd',
  storageBucket: 'easebite-53fcd.firebasestorage.app',
  messagingSenderId: '65088955971',
  appId: '1:65088955971:web:1946e12c7ba10ea2fc96f6',
  measurementId: 'G-LR2V6T4LM6',
};

let app;
let messaging;

try {
  // Initialize Firebase
  app = initializeApp(firebaseConfig);

  // Initialize Firebase Messaging

  messaging = getMessaging(app);
} catch (error) {
  console.error('Error initializing Firebase:', error);
}

// Function to call API to save token
const saveToken = async (token, admin) => {
  try {
    await generatePostCall({
      apiPath: admin
        ? API_ENDPOINT_NAME.ADMIN_SAVE_FCM_TOKEN
        : API_ENDPOINT_NAME.EMPLOYEE_SAVE_FCM_TOKEN,
      data: { fcmToken: token },
    });
  } catch (error) {
    console.error('Error saving FCM token:', error);
  }
};

// Function to generate FCM token
export const generateToken = async ({ admin = false }) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const currentToken = await getToken(messaging, {
        vapidKey:
          'BAPah1BQFoCmHxJ6XBpMtiTKUfPHUHoC5FN3OIbEqe-kW2w7oI4IAuSJ0oXiFl-BGKAMjzmoaGw1mdKeAobQYKc',
      });

      if (currentToken) {
        await saveToken(currentToken, admin);
        return currentToken;
      } else {
        console.warn('No registration token available.');
        return null;
      }
    } else {
      console.warn('Permission not granted for Notifications');
      return -1;
    }
  } catch (error) {
    console.error('Error generating token:', error);
    return null; // Return null to indicate failure
  }
};

export { messaging };

// // Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { generatePostCall } from '../api_calls/generatePostCall';
// import { API_ENDPOINT_NAME } from '../utils/constants';

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyBm8918p1AoN284kl-G0lg_k8fkNvYMQEg',
//   authDomain: 'easebite-53fcd.firebaseapp.com',
//   projectId: 'easebite-53fcd',
//   storageBucket: 'easebite-53fcd.firebasestorage.app',
//   messagingSenderId: '65088955971',
//   appId: '1:65088955971:web:1946e12c7ba10ea2fc96f6',
//   measurementId: 'G-LR2V6T4LM6',
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// export const messaging = getMessaging(app);

// //CALL API TO SAVE TOKEN
// const saveToken = async (token, admin) => {
//   const response = await generatePostCall({
//     apiPath: admin
//       ? API_ENDPOINT_NAME.ADMIN_SAVE_FCM_TOKEN
//       : API_ENDPOINT_NAME.EMPLOYEE_SAVE_FCM_TOKEN,
//     data: { fcmToken: token },
//   });
// };
// //CALL API TO SAVE TOKEN

// export const generateToken = async ({ admin = false }) => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === 'granted') {
//       const currentToken = await getToken(messaging, {
//         vapidKey:
//           'BAPah1BQFoCmHxJ6XBpMtiTKUfPHUHoC5FN3OIbEqe-kW2w7oI4IAuSJ0oXiFl-BGKAMjzmoaGw1mdKeAobQYKc',
//       });
//       // console.log('Token:', currentToken);
//       // alert(currentToken);
//       await saveToken(currentToken, admin);

//       return currentToken;
//     }

//     // if permission is not granted, return -1
//     return -1;
//   } catch (err) {
//     console.log(err);
//   }
// };
