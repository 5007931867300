import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminIndex from '../views/adminFood/AdminIndex';
import CartIndex from './cart/CartIndex';
import AdminTables from '../views/adminTables/AdminTables';
import styles from '../styles/admin/adminIndex.module.scss';
// import NavigationBar from './navigation_bar/NavigationBar';
import LogoHeader from './Header/Header';
import AdminSideBar from '../views/adminFood/AdminSideBar';
import Employee from '../views/employee/Employee';
import { pullRestaurantPublicInfo } from '../api_calls/pullRestaurantPublicInfo';
import { getLocalStorageItem } from '../utils/generalFunction';
import AdminOrder from '../views/adminOrders.js/AdminOrder';
// import { updatePageNumber } from '../../../redux/reducers/PageScrollSlice';

function Admin() {
  const adminFeatures = [
    {
      sectionName: 'Profile',
      url: '/profile/*',
      component: <AdminIndex />,
    },
    {
      sectionName: 'menu',
      url: '/menu/:categoryName?',
      component: <AdminIndex />,
    },
    {
      sectionName: 'cart',
      url: '/cart',
      component: <CartIndex />,
    },
    {
      sectionName: 'orderList',
      url: '/orderList/*',
      component: <AdminOrder />,
    },
    {
      sectionName: 'tables',
      url: '/tables/*',
      component: <AdminTables />,
    },
    {
      sectionName: 'employee',
      url: '/employee/:employeeId?',
      component: <Employee />,
    },
  ];
  const [sideBar, setSideBar] = useState(false);
  const [addItemOpener, setAddItemOpener] = useState(false);
  const [category, setCategory] = useState();

  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const scroll = useSelector((state) => state.pageScroll);

  useEffect(() => {
    if (getLocalStorageItem('restaurantId')) {
      pullRestaurantPublicInfo(dispatch, getLocalStorageItem('restaurantId'));
    }
  }, []);

  const RedirectToHome = () => {
    return <Navigate to="/admin/dashboard" />;
  };

  return (
    <>
      {sideBar ? (
        <AdminSideBar sideBar={sideBar} setSideBar={setSideBar} />
      ) : null}{' '}
      <div className={styles.AdminIndex_main_container_wrapper}>
        <div className={styles.AdminIndex_main_container}>
          {/* <NavigationBar /> */}
          {!category && !addItemOpener && (
            <LogoHeader setSideBar={setSideBar} admin={true} styles={styles} />
          )}

          <Routes>
            <Route path="/" element={<RedirectToHome />} />
            {adminFeatures.map((feature, index) => (
              <Route
                key={index}
                path={feature.url}
                element={feature.component}
              />
            ))}
          </Routes>
        </div>
      </div>
    </>
  );
}

export default Admin;
