import { setRestaurantPublicInfo } from '../store/restaurantPublicInfo';
import { API_ENDPOINT_NAME } from '../utils/constants';
import { setLocalStorageItem } from '../utils/generalFunction';
import { generateGetCall } from './generateGetCall';
import { getFileData } from './getFileData';

// returns the URL of the profile photo
const getProfilePhotoDetails = async (restaurantId, assetDetails) => {
  let responseBlob = await getFileData({
    apiPath: API_ENDPOINT_NAME.RESTAURANT_PUBLIC_IMAGE,
    data: {
      restaurantId: restaurantId,
      ...assetDetails,
      assetType: 'LOGO',
    },
  });

  if (responseBlob) {
    let url = URL.createObjectURL(responseBlob.fileBlob);
    return url;
  }
  return null;
};

// set all the public info of the restaurant and logo in local storage

const setRestaurantPublicInfoLocalStorage = async (data) => {
  try {
    Object.keys(data).forEach((key) => {
      setLocalStorageItem(key, data[key]);
    });
  } catch (error) {
    console.log('Error setRestaurantPublicInfoLocalStorage ', error);
  }
};

// `dispatch` is passed as an argument from a component
const pullRestaurantPublicInfo = async (dispatch, restaurantId = null) => {
  try {
    // Assuming `apiCall` is a function that handles the actual API request
    let response = await generateGetCall({
      apiPath: API_ENDPOINT_NAME.RESTAURANT_PUBLIC_INFO,
      pathId: restaurantId,
    });

    if (response.success) {
      let url = await getProfilePhotoDetails(
        restaurantId,
        response.response.data.data.restaurantLogo
      );

      let allPublicInfo = {
        restaurantId: restaurantId,
        restaurantName: response.response.data.data.restaurantName,
        restaurantLogo: response.response.data.data.restaurantLogo,
        restaurantLogoBlobUrl: url,
        primaryColour: response.response.data.data.primaryColour,
      };

      dispatch(setRestaurantPublicInfo(allPublicInfo));

      await setRestaurantPublicInfoLocalStorage(allPublicInfo);

      // console.log('getProfilePhotoDetails', url);
    }
  } catch (error) {
    console.log('Error generateGetCall ', restaurantId, error);
  }
};

export { pullRestaurantPublicInfo };
