import React, { useEffect, useState } from 'react';
import styles from '../../styles/employee/employeeIndex.module.scss';
// import img from '../../assets/employee/75b8682b79639bf2dcb842c50ee4ddd2.png';
import EmployeeDisplay from './EmployeeDisplay';
import AddNewEmployee from './AddNewEmployee';
import { useParams } from 'react-router-dom';
import { generateGetCall } from '../../api_calls/generateGetCall';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { formatDate, formatTime } from '../../utils/generalFunction';
// import LogoHeader from '../../components/logoHeader/LogoHeader';

// const data = [
//   {
//     _id: '1',
//     name: 'Robert Kipp',
//     role: 'CHEF',
//     dateJoined: '24-05-2021',
//     mobile: '7023966574',
//     email: 'robert.kipp@darkbuild.com',
//     lastLogin: '24-06-2024',
//     active: true,
//     img,
//     address: 'Mr Robert Kipp. 132, My Street, Kingston, New York 12401.',
//     employeeDocuments: [
//       {
//         name: 'Aadhar Card.jpg',
//       },
//       {
//         name: 'Aadhar Card.jpg',
//       },
//     ],
//   },
//   {
//     _id: '2',
//     name: 'Robert Kipp',
//     role: 'CHEF',
//     dateJoined: '24-05-2021',
//     mobile: '7023966574',
//     email: 'robert.kipp@darkbuild.com',
//     lastLogin: '24-06-2024',
//     active: false,
//     img,
//     address: 'Mr Robert Kipp. 132, My Street, Kingston, New York 12401.',
//     employeeDocuments: [
//       {
//         name: 'Aadhar Card.jpg',
//       },
//       {
//         name: 'Aadhar Card.jpg',
//       },
//     ],
//   },
//   {
//     _id: '3',
//     name: 'Robert Kipp',
//     role: 'CHEF',
//     dateJoined: '24-05-2021',
//     mobile: '7023966574',
//     email: 'robert.kipp@darkbuild.com',
//     lastLogin: '24-06-2024',
//     active: true,
//     img,
//     address: 'Mr Robert Kipp. 132, My Street, Kingston, New York 12401.',
//     employeeDocuments: [
//       {
//         name: 'Aadhar Card.jpg',
//       },
//       {
//         name: 'Aadhar Card.jpg',
//       },
//     ],
//   },
// ];

const Employee = () => {
  const [sideBar, setSideBar] = useState();
  const [addEmp, setAddEmp] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState();
  const [data, setData] = useState([]);
  const employeeId = useParams().employeeId;

  const pullEmployeeData = async () => {
    try {
      const response = await generateGetCall({
        apiPath: API_ENDPOINT_NAME.GET_EMPLOYEES,
      });

      // map the response to the data array
      let data = response.response.data.data;

      // change lastActive to lastLogin and createdAt to dateJoined
      data = data.map((employee) => {
        return {
          ...employee,
          lastLogin:
            formatDate(employee.lastActive) +
            ' : ' +
            formatTime(employee.lastActive),
          dateJoined: formatDate(employee.createdAt),
        };
      });

      console.log(data);

      setData(data);

      // return response;
    } catch (error) {
      console.error('Error fetching employees:', error);
      // throw error;
    }
  };

  useEffect(() => {
    // console.log(selectedProfile);
    pullEmployeeData();
    if (employeeId) {
      if (employeeId === 'add') {
        setAddEmp(true);
      } else {
        // setAddEmp(false);
        setSelectedProfile(data[employeeId]);
        setAddEmp(true);
      }
    } else {
      setAddEmp(false);
    }
  }, [selectedProfile, employeeId]);

  return (
    <>
      <div className={styles.AdminIndex_main_container_wrapper}>
        {addEmp ? (
          <AddNewEmployee
            selectedProfile={selectedProfile}
            setAddEmp={setAddEmp}
          />
        ) : (
          <EmployeeDisplay
            data={data}
            sideBar={sideBar}
            setSideBar={setSideBar}
            styles={styles}
            setAddEmp={setAddEmp}
            setSelectedProfile={setSelectedProfile}
          />
        )}
      </div>
    </>
  );
};

export default Employee;
