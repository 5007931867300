import React, { useEffect } from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '8rem', // Set width to 8rem
    border: 'none', // Remove border
    outline: 'none !important',
  }),
};

const CountryCodeDropDown = ({ options, setSelectedCountryCode }) => {
  const selectOptions = options.map(option => ({
    label: `${ option.code } (${ option.flag }) ${ option.dial_code }`,
    value: option.dial_code
  }));

  const handleSelectChange = (selectedOption) => {
    setSelectedCountryCode(selectedOption.value); // Pass the selected dial code to the onChange function
  };
  const defaultOption = selectOptions.find(option => option.value === '+91');
  
  useEffect(() => {
    if (defaultOption) {
      setSelectedCountryCode(defaultOption.value);
    }
  }, [defaultOption]);

  return defaultOption && <Select
    options={selectOptions}
    styles={customStyles}
    onChange={handleSelectChange} // Handle onChange event
    defaultValue={defaultOption}
  /> ;
};

export default CountryCodeDropDown;
