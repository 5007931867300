import Axios from '../axios';
import { USER_TYPE, apiEndpointname } from '../utils/constants';

const getFileData = async ({ apiPath, pathId, data = {} }) => {
  apiPath = pathId ? `${apiPath}/${pathId}` : apiPath;

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: apiPath,
    headers: {
      // Accept: "image/png", // Adjust the Accept header according to the file type you expect
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    params: data,
    responseType: 'blob', // Ensure response type is set to 'arraybuffer'
  };

  try {
    const response = await Axios.request(config);
    if (response.status === 200 || response.status === 201) {
      const fileType = response.headers['content-type'];
      // console.log("fileType", fileType);
      const blob = new Blob([response.data], { type: fileType }); // Create Blob with correct content type
      return { fileBlob: blob, fileType: fileType };
    } else {
      console.log('error getSingleData 1 ', apiPath, response);
      throw new Error('File retrieval error');
    }
  } catch (error) {
    // console.log("error getSingleData ", apiPath, error);
    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      localStorage.clear();
    }
  }
};

export { getFileData };
