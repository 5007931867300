import React, { useState } from 'react';
import styles from '../../styles/admin/AdminTables.module.scss';
import UserTablesPage from './UserTablesPage';

const UserTables = () => {
  const [table, setTable] = useState();
  const [historyTable, sethistoryTable] = useState(false);
  const [tablesData, setTablesData] = useState([]);

  return (
    <>
      <div className={styles.main_container_admin_tables}>
        {!table && (
          <UserTablesPage
            tablesData={tablesData}
            setTablesData={setTablesData}
            historyTable={historyTable}
            sethistoryTable={sethistoryTable}
            setTable={setTable}
          />
        )}
      </div>
    </>
  );
};

export default UserTables;
