import React, { useEffect, useState } from 'react';
import styles from '../../styles/admin/adminTablesPage.module.scss';
import TableNumberList from './TableNumberList';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { generateGetCall } from '../../api_calls/generateGetCall';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as SixSitter } from '../../assets/components/tablesIcon/sixSitter.svg';
import { ReactComponent as FourSitter } from '../../assets/components/tablesIcon/fourSitter.svg';

const fetchTablesData = async (restaurantId) => {
  try {
    const response = await generateGetCall({
      apiPath: API_ENDPOINT_NAME.RESTAURANT_TABLES_INFO,
      pathId: restaurantId,
    });
    return response;
  } catch (error) {
    console.error('Error fetching tables:', error);
    // throw error;
  }
};

const UserTablesPage = ({ tablesData, setTablesData, setTable }) => {
  // const [tablesData, setTablesData] = useState([]);
  const navigate = useNavigate();
  const restaurantId = useParams().restaurantId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchTablesData(restaurantId);
        if (response.success) {
          const tablesData = response.response.data.data;
          const tablesWithDataAndIcons = tablesData.map((table) => {
            if (table.capacity < 4) {
              return {
                ...table,
                icon: <FourSitter className={styles.primaryColorStroke} />,
              };
            } else {
              return {
                ...table,
                icon: <SixSitter className={styles.primaryColorStroke} />,
              };
            }
          });
          setTablesData(tablesWithDataAndIcons);

          return tablesWithDataAndIcons;
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching tables data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className={styles.adminTablesPage_main}>
        <header>
          <h3>Tables</h3>
        </header>
        <span>please select a table at which you are currently seated</span>

        {tablesData?.length > 0 && (
          <TableNumberList
            setTable={setTable}
            data={tablesData}
            redirectToUser={true}
            restaurantId={restaurantId}
          />
        )}
      </div>
    </>
  );
};

export default UserTablesPage;
