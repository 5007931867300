import React from 'react';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import styles from '../../styles/components/cart/discountIndexPage.module.scss';
import DiscountList from './DiscountList';

const DiscountIndexPage = ({
  discountList,
  seletedDiscount,
  setSelectedDiscount,
  setDiscountPage,
}) => {
  return (
    <>
      <div className={styles.DiscountIndexPage_main}>
        <header>
          {/* <img
            onClick={() => setDiscountPage(null)}
            src={backArrow}
            alt="backArrow"
          /> */}
          <BackArrow
            onClick={() => setDiscountPage(null)}
            className={styles.primaryColor}
          />
          <span>All Discounts</span>
        </header>

        <div className={styles.DiscountIndexPage_main_inner}>
          <div className={styles.details_header}>
            <span>
              Apply Discounts
              <span className={styles.discount_highlighter}>%</span>
            </span>
            <span className={styles.discount_highlighter}>
              You saved AUD500{' '}
            </span>
          </div>
          {discountList.map((item, index) => {
            return (
              <DiscountList
                key={index}
                item={item}
                seletedDiscount={seletedDiscount}
                setSelectedDiscount={setSelectedDiscount}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
export default DiscountIndexPage;
