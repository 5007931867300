import React, { useRef } from 'react';
import styles from '../../styles/admin/tableNumberList.module.scss';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import useDynamicHeight from '../../utils/useDynamicHeight';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HandRaiseLogo } from '../../assets/employee/handRaiseLogo.svg';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate.replace(/\//g, '-');
}

// Function to format the time in AM/PM
function formatTime(timeString) {
  const time = new Date(timeString);
  const options = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedTime = time.toLocaleTimeString('en-US', options);
  return formattedTime;
}

const TableNumberList = ({
  history,
  data,
  setTable,
  historyTable,
  redirectToUser = false,
  restaurantId = '',
  handRaisedTables = [],
  setHandRaisedTables = () => {},
  setSelectedOrder = () => {},
  defaulHandVisible = false,
}) => {
  const myElementRef = useRef();
  const dynamicHeight = useDynamicHeight(myElementRef, true);
  const navigate = useNavigate();

  return (
    <ul
      className={styles.tableNumber_list_ul}
      ref={myElementRef}
      style={{ maxHeight: dynamicHeight }}
    >
      <div className={styles.header_line}></div>
      {data?.map((item, index) => {
        return (
          <TableItem
            setTable={setTable}
            key={index}
            item={item}
            navigate={navigate}
            redirectToUser={redirectToUser}
            restaurantId={restaurantId}
          >
            {!history && !historyTable ? (
              <div className={styles.table_details}>
                {item?.orders && item?.orders[0]?.createdAt && (
                  <>
                    <span>{formatDate(item?.orders[0]?.createdAt)}</span>
                    <span>{formatTime(item?.orders[0]?.createdAt)}</span>
                  </>
                )}

                {item?.orders?.length > 0 ? (
                  <span className={styles.numberOfOrder}>
                    {item?.orders?.length}
                  </span>
                ) : null}

                {handRaisedTables.includes(item.id) === false &&
                defaulHandVisible === false ? null : (
                  <span className={styles.handRaiseLogo}>
                    <HandRaiseLogo
                      onClick={(e) => {
                        e.stopPropagation();
                        setHandRaisedTables(
                          handRaisedTables.includes(item.id)
                            ? handRaisedTables.filter(
                                (tableId) => tableId !== item.id
                              )
                            : [...handRaisedTables, item.id]
                        );
                      }}
                      style={{
                        fill: handRaisedTables.includes(item.id)
                          ? 'red'
                          : 'black',
                        cursor: 'pointer',
                        width: '30px',
                        height: '30px',
                      }}
                    />
                  </span>
                )}
              </div>
            ) : (
              <BackArrow
                style={{ transform: 'scaleX(-1)' }}
                className={styles.primaryColorFill}
              />
            )}
          </TableItem>
        );
      })}
    </ul>
  );
};

export default TableNumberList;

const TableItem = ({
  item,
  setTable,
  children,
  navigate,
  redirectToUser = false,
  restaurantId = '',
  setSelectedOrder = () => {},
}) => {
  return (
    <>
      <li
        onClick={() => {
          if (redirectToUser) {
            navigate(`/${restaurantId}/dineIn/${item._id}`);
          } else {
            if (item?.orders?.length > 0) {
              setTable(item);
              setSelectedOrder(item.orders);
            } else {
              alert('There is no active orders at this table');
            }
          }
        }}
      >
        <div className={styles.table_icons_and_names}>
          {/* <img src={item.icon} alt="icon" /> */}

          {/* <item.icon className={styles.primaryColorFill} /> */}
          <span className={styles.tableIcon}>{item.icon}</span>

          <span>
            {item.tableNumber} - {item.capacity} Seater
          </span>
        </div>
        {children}
      </li>
      <div className={styles.header_line}></div>
    </>
  );
};
