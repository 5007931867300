import React, { useEffect, useState } from 'react';
import styles from '../../styles/takeAway/ordersIndex.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import OrderPage from './OrderPage.js';
import OrderHeader from './OrderHeader.js';
import OrderHistoryPage from './OrderHistoryPage.js';
import OrderAnimationOne from './orderAnimation/OrderAnimationOne.js';
import OrderAnimationTwo from './orderAnimation/OrderAnimationTwo.js';
import OrderAnimationThree from './orderAnimation/OrderAnimationThree.js';
import OrderAnimationFour from './orderAnimation/OrderAnimationFour.js';
import { generateGetCall } from '../../api_calls/generateGetCall.js';
import { API_ENDPOINT_NAME } from '../../utils/constants.js';
import BillingPage from './BillingPage.js';

const AnimationComponent = ({ setAnimationState }) => {
  const [selectedAnimation, setSelectedAnimation] = useState(null);

  useEffect(() => {
    const animations = [
      <OrderAnimationOne key={1} />,
      <OrderAnimationTwo key={2} />,
      <OrderAnimationThree key={3} />,
      <OrderAnimationFour key={4} />,
    ];

    const randomIndex = Math.floor(Math.random() * animations.length);
    setSelectedAnimation(animations[randomIndex]);

    const timeoutId = setTimeout(() => {
      setAnimationState(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId); // Clear timeout on unmount
    };
  }, [setAnimationState]);

  return (
    <div className={styles.order_index_animation_wrapper}>
      {selectedAnimation}
      <div className={styles.progressBar_wrapper}>
        <div></div>
      </div>
      <span>Hold up page loading...</span>
    </div>
  );
};

const OrdersIndex = ({
  isEmployee,
  setTable = () => {},
  q_restaurantId,
  q_tableNumber,
  billingDetails = false,
  isUser = true,
}) => {
  const location = useLocation();
  const initialState = location.state;
  const [animationState, setAnimationState] = useState(initialState?.animation);
  const [orderHistory, setOrderHistory] = useState(false);
  const [orderState, setOrderState] = useState();
  const { tableNumber, restaurantId } = useParams();

  const fetchData = async () => {
    let data = {
      restaurantId: restaurantId || q_restaurantId,
      tableId: tableNumber || q_tableNumber,
    };

    const response = await generateGetCall({
      apiPath: `${API_ENDPOINT_NAME.GET_ORDER}`,
      additionalData: data,
    });
    if (response.success) {
      setOrderState(response.response.data.orders);
      console.log(response.response.data.orders);
    }
  };

  useEffect(() => {
    fetchData();
  }, [orderHistory]);

  return (
    <>
      <div className={styles.order_page_index_wrapper}>
        {!animationState && tableNumber && (
          <OrderHeader
            styles={styles}
            orderHistory={orderHistory}
            setOrderHistory={setOrderHistory}
            tableNumber={tableNumber}
            animationState={animationState}
          ></OrderHeader>
        )}
        {animationState ? (
          <AnimationComponent setAnimationState={setAnimationState} />
        ) : orderHistory ? (
          <OrderHistoryPage />
        ) : billingDetails ? (
          <>
            <BillingPage
              orderState={orderState}
              styles={styles}
              isEmployee={isEmployee}
              q_restaurantId={q_restaurantId}
              q_tableNumber={q_tableNumber}
              setTable={setTable}
              billingDetails={billingDetails}
            />
          </>
        ) : (
          <OrderPage
            orderState={orderState}
            styles={styles}
            isEmployee={isEmployee}
            q_restaurantId={q_restaurantId}
            q_tableNumber={q_tableNumber}
            setTable={setTable}
            billingDetails={billingDetails}
            isUser={isUser}
          />
        )}
      </div>
    </>
  );
};

export default OrdersIndex;
