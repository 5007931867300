// restaurantPublicInfo.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  restaurantId: null,
  restaurantName: null,
  restaurantLogo: null,
  restaurantLogoBlobUrl: null,
  primaryColour: null,
};

const restaurantPublicInfo = createSlice({
  name: 'restaurantPublicInfo',
  initialState,
  reducers: {
    setRestaurantPublicInfo(state, action) {
      state.restaurantId = action.payload.restaurantId;
      state.restaurantName = action.payload.restaurantName;
      state.restaurantLogo = action.payload.restaurantLogo;
      state.restaurantLogoBlobUrl = action.payload.restaurantLogoBlobUrl;
      state.primaryColour = action.payload.primaryColour;
    },

    resetRestaurantPublicInfo(state) {
      state.restaurantId = null;
      state.restaurantName = null;
      state.restaurantLogo = null;
      state.restaurantLogoBlobUrl = null;
    },

    // update based on the key
    updateRestaurantPublicInfo(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const restaurantLogoBlobUrl = (state) =>
  state.restaurantPublicInfo.restaurantLogoBlobUrl;

export const {
  setRestaurantPublicInfo,
  resetRestaurantPublicInfo,
  updateRestaurantPublicInfo,
} = restaurantPublicInfo.actions;

export default restaurantPublicInfo.reducer;
