import React, { useRef } from 'react';
import styles from '../../styles/components/categoryIndex/categoryIndex.module.scss';
import CategoryItem from './CategoryItem';
import useDynamicHeight from '../../utils/useDynamicHeight.js';
import addIcon from '../../assets/admin/addIcon.svg';
import { ReactComponent as EmptyIcon } from '../../assets/takeAway/categoryIndex/emptyCategory.svg';
const EmptyIndex = () => {
  return (
    <>
      <div className={styles.icon_wrapper}>
        <span></span>
      </div>
      <div className={styles.line_wrapper}></div>
      <div className={styles.dot_wrapper}></div>
    </>
  );
};

const CategoryIndex = ({
  setAddItemOpener,
  categoryIndexData,
  setCategory,
  isadmin,
  setEditCategory,
}) => {
  const myElementRef = useRef(null);
  const dynamicHeight = useDynamicHeight(myElementRef, isadmin);
  // console.log(dynamicHeight);

  return (
    <div className={styles.categoryIndex_main_con}>
      <header>
        <h2>Category</h2>
        {isadmin ? (
          <button onClick={() => setAddItemOpener(true)}>
            Add Food <img src={addIcon} alt="addIcon" />
          </button>
        ) : null}
      </header>

      <div
        ref={myElementRef}
        className={styles.categoryIndex_inner_con}
        style={{ maxHeight: dynamicHeight }}
      >
        {categoryIndexData.length > 0 ? (
          categoryIndexData?.map((item, index) => (
            <React.Fragment key={index}>
              <CategoryItem
                item={item}
                setCategory={setCategory}
                isadmin={isadmin}
                setEditCategory={setEditCategory}
              />
              {index !== categoryIndexData.length - 1 && (
                <div className={styles.horizontal_rulling}></div>
              )}
            </React.Fragment>
          ))
        ) : (
          <>
            <div className={styles.categoryIndex_empty}>
              {/* <EmptyIndex /> */}
              {/* <EmptyIndex /> */}
              {/* <EmptyIndex /> */}
              {/* <EmptyIndex /> */}
              <div className={styles.emptyCategoryIndex}>
                {/* <img src={emptyIcon} alt="empty" /> */}

                <EmptyIcon className={styles.primaryColorStroke} />

                <h3>No Items Added!</h3>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryIndex;
