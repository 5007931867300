import React, { useState, useEffect } from 'react';
import { addInstuctionToOrder } from '../../store/orderSlice';
import { useDispatch } from 'react-redux';
const AddInstructions = ({
  styles,
  instructions,
  setInstructions,
  addInstructions,
  setAddInstructions,
}) => {
  const [value, setValue] = useState(instructions || '');
  const dispatch = useDispatch();
  useEffect(() => {
    const cartWrapper = document.getElementById('cart_main_wrapper_id');
    if (cartWrapper) {
      cartWrapper.style.overflow = 'hidden';
    }
    return () => {
      if (cartWrapper) {
        cartWrapper.style.overflow = 'auto';
      }
    };
  }, []);

  const handleCloseAddInstruction = (e) => {
    setAddInstructions(false);
    e.stopPropagation();
  };

  const handleSaveAddInstruction = (e) => {
    setInstructions(value);
    setAddInstructions(false);
    e.stopPropagation();
    dispatch(addInstuctionToOrder(value));
  };

  return (
    <>
      <div className={styles.addMoreItemWrapper}>
        <div className={styles.addMoreItemWrapper_inner}>
          <h2>Add Instructions for cooking</h2>
          <textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            key={addInstructions}
          />
          <div className={styles.button_wrapper}>
            <button onClick={handleCloseAddInstruction}>Cancel</button>
            <button onClick={handleSaveAddInstruction}>Save</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInstructions;
