import React, { useEffect, useState } from 'react';
import styles from '../../styles/components/cart/cart.module.scss';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import OrderItem from './OrderItem';
import addMoreItem from '../../assets/components/common/AddMoreItem.svg';
import DiscountIndexPage from './DiscountIndexPage.js';
import AddInstructions from './AddInstructions.js';
import BillingCard from './BillingCard.js';
import ContinueOrder from './ContinueOrder.js';
import UserDetails from './UserDetails.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToPlacedOrder,
  getCompleteOrder,
  getOrderInstructions,
  resetOrder,
  selectOrders,
  updateOrderFromLocalStorage,
} from '../../store/orderSlice.js';
import { generatePostCall } from '../../api_calls/generatePostCall.js';
import { API_ENDPOINT_NAME } from '../../utils/constants.js';

const CartIndex = () => {
  const ordersList = useSelector(selectOrders);
  const navigate = useNavigate();
  const [indexPage, setIndexPage] = useState(false);
  const storeInstructions = useSelector(getOrderInstructions);
  const [addInstructions, setAddInstructions] = useState(false);
  const [instructions, setInstructions] = useState(storeInstructions || '');
  const [selectedDiscount, setSelectedDiscount] = useState();
  const [discountList, setDiscountList] = useState();
  const completeOrder = useSelector(getCompleteOrder);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!ordersList || ordersList.length === 0) {
      if (localStorage.getItem('order')) {
        dispatch(updateOrderFromLocalStorage());
      }
    }
  }, []);
  const { tableNumber, restaurantId } = useParams();

  const skipUserDetailsOrder = async () => {
    if (window.location.href.includes('dineIn') === true) {
      console.log(completeOrder);
      completeOrder.tableNumber = tableNumber;
      const response = await generatePostCall({
        apiPath: `${API_ENDPOINT_NAME.PLACE_ORDER}?restaurantId=${restaurantId}&tableId=${tableNumber}`,
        data: completeOrder,
      });
      if (response.success) {
        dispatch(addToPlacedOrder(response.response.data.data));
        dispatch(resetOrder());
        navigate(`/${restaurantId}/dineIn/${tableNumber}/orders`, {
          state: { animation: true },
        });
      }
    }
  };

  return (
    <>
      {indexPage === 'discount' ? (
        <DiscountIndexPage
          discountList={discountList}
          setDiscountPage={setIndexPage}
          selectedDiscount={selectedDiscount}
          setSelectedDiscount={setSelectedDiscount}
        />
      ) : indexPage === 'userDetail' ? (
        <UserDetails setIndexPage={setIndexPage} />
      ) : (
        <div className={styles.cart_main} id="cart_main_wrapper_id">
          <header>
            <BackArrow
              onClick={() => navigate(-1)}
              className={styles.primaryColorFill}
            />
            <span>Cart</span>
          </header>
          <div className={styles.selected_item_wrapper}>
            <h3>Selected items</h3>
            {ordersList && ordersList.length > 0 ? (
              ordersList.map((item, index) => (
                <OrderItem key={index} data={item} />
              ))
            ) : (
              <p>No items in the cart. Add some!</p>
            )}
            {instructions ? (
              <div className={styles.addInstructions_wrapper}>
                <h2 onClick={() => setAddInstructions(true)}>
                  Edit instructions
                </h2>
                <p>{instructions}</p>
              </div>
            ) : (
              <button
                onClick={() => setAddInstructions(true)}
                className={styles.addd_instructions_btn}
              >
                Add instructions
              </button>
            )}
            <button
              onClick={() => navigate(-1)}
              className={styles.add_items_btn}
            >
              Add More Items
              <img src={addMoreItem} alt="addMoreItem" />
            </button>
          </div>
          {addInstructions && (
            <AddInstructions
              styles={styles}
              instructions={instructions}
              setInstructions={setInstructions}
              addInstructions={addInstructions}
              setAddInstructions={setAddInstructions}
            />
          )}
          <BillingCard
            orders={ordersList}
            selectedDiscount={selectedDiscount}
          />
          <ContinueOrder
            setIndexPage={setIndexPage}
            directOrder={true}
            skipUserDetailsOrder={skipUserDetailsOrder}
          />
        </div>
      )}
    </>
  );
};

export default CartIndex;
