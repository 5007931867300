import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/chefScreen/chefIndex.module.scss';
import { ReactComponent as EmptyOrderListLogo } from '../../assets/employee/emptyOrderList.svg';
import { io } from 'socket.io-client';
import { getLocalStorageItem } from '../../utils/generalFunction.js';
import bellWav from '../../assets/audioFile/mixkit-happy-bells-notification-937.wav';
import startEndWav from '../../assets/audioFile/mixkit-software-interface-start-2574.wav';
import { generateToken } from '../../notification/firebase.js';
import { onMessage } from 'firebase/messaging';
import { generateGetCall } from '../../api_calls/generateGetCall.js';
import { API_ENDPOINT_NAME, SOCKET_HOST_PROD } from '../../utils/constants.js';
import { useSelector } from 'react-redux';
import OrderItemCard from '../orderPage/OrderItemCard.js';

const AdminOrder = () => {
  const [ordersList, setOrders] = useState([]);
  const socket = useRef(null);

  const host = SOCKET_HOST_PROD;
  const restaurantPublicInfo = useSelector(
    (state) => state.restaurantPublicInfo
  );

  const bellAudio = useRef(new Audio(bellWav));
  const startAudio = useRef(new Audio(startEndWav));

  // Utility to play audio with error handling
  const playAudio = async (audio) => {
    try {
      await audio.play();
    } catch (error) {
      console.error('Audio play failed:', error);
    }
  };

  // Socket initialization and event handling
  const initializeSocket = () => {
    if (socket.current) socket.current.disconnect();

    socket.current = io(host, {
      auth: { token: getLocalStorageItem('accessToken') },
    });

    // socket.current.on('connect', () => {
    //   console.log('Socket connected');
    //   //   socket.current.emit('mark-activity', { activity: 'ACTIVE' });
    //   //   playAudio(startAudio.current); // Play start sound
    // });

    socket.current.on('newOrder', (tableOrder) => {
      console.log('New order received', tableOrder);
      playAudio(bellAudio.current); // Play bell sound
      addOrderToList(tableOrder.order);
    });

    socket.current.on('disconnect', () => {
      console.log('Socket disconnected');
    });
  };

  const addOrderToList = (newOrder) => {
    setOrders((prevOrders) => {
      const existingOrderIndex = prevOrders.findIndex(
        (order) => order.tableId === newOrder.tableId
      );

      if (existingOrderIndex !== -1) {
        const updatedOrders = [...prevOrders];
        updatedOrders[existingOrderIndex] = newOrder;
        return updatedOrders;
      }

      return [...prevOrders, newOrder];
    });
  };

  // Fetch orders from API
  const fetchOrders = async () => {
    const response = await generateGetCall({
      apiPath: API_ENDPOINT_NAME.EMPLOYEE_ALL_ORDERS,
      additionalData: { limit: 5000 },
    });

    if (response.success) {
      setOrders(response.response.data.data);
    }
  };

  // Firebase initialization for notifications
  const initializeFirebase = async () => {
    await generateToken({ admin: true });
    onMessage((payload) => {
      console.log('Firebase message received: ', payload);
    });
  };

  // Effect for socket initialization, fetching data, and Firebase setup
  useEffect(() => {
    fetchOrders();

    const { protocol, hostname } = window.location;
    if (protocol === 'https:' || hostname === 'localhost') {
      initializeFirebase();
    } else {
      console.warn('Firebase initialization skipped: Non-secure connection.');
    }

    initializeSocket();

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  return (
    <div className={styles.chefIndex_wrapper}>
      {ordersList.length ? (
        ordersList.map((order, index) => (
          <OrderItemCard orderList={order} key={index} isUser={false} />
        ))
      ) : (
        <div className={styles.emptyOrderList}>
          <EmptyOrderListLogo className={styles.primaryColorFill} />
          <p>No orders available</p>
        </div>
      )}
    </div>
  );
};

export default AdminOrder;
