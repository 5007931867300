import React from 'react';
import OrderAnimationOne from '../../views/orderPage/orderAnimation/OrderAnimationOne';
import styles from '../../styles/comman/AnimationWrapper.module.scss';

const AnimationWrapper = () => {
  return (<>
    <div className={styles.main_wrapper_animation_wrapper} >
      <OrderAnimationOne key={1} />
    </div>
  </>);
};

export default AnimationWrapper;
