import React, { useEffect, useState } from 'react';
import styles from '../../styles/admin/adminIndex.module.scss';
import LogoHeader from '../../components/Header/Header';
import AdminSideBar from './AdminSideBar';
import SearchComponent from '../../components/searchComponent/SearchComponent';
import CategoryIndex from '../../components/category/CategoryIndex';
import ItemMenuIndex from '../../components/category/ItemMenuIndex';
import SideMenuButton from '../../components/sideMenuButton/SideMenuButton';
import AddItemIndex from './AdditemIndex';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { generateGetCall } from '../../api_calls/generateGetCall';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories, storeAllCategory } from '../../store/categorySlice';
import SearchDataResults from './SearchDataResult';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocalStorageItem } from '../../utils/generalFunction';

const AdminIndex = () => {
  const categories = useSelector(getAllCategories);
  const [sideBar, setSideBar] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchDataState, setsearchDataState] = useState();
  const [category, setCategory] = useState();
  const [addItemOpener, setAddItemOpener] = useState(false);
  const dispatch = useDispatch();
  const [EditCategory, setEditCategory] = useState();
  const [initialSelectedState, setInitialSelectedState] = useState(null);
  const navigate = useNavigate();

  async function fetchData() {
    const response = await generateGetCall({
      apiPath: `${API_ENDPOINT_NAME.GET_ADMIN_CATEGORY}`,
    });
    if (response.success) {
      // console.log(response.response.data.categoriesWithDishes);
      dispatch(storeAllCategory(response.response.data.categoriesWithDishes));
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    if (getLocalStorageItem('accessToken')) {
      fetchData();
    } else {
      navigate('/login');
    }
  }, []);

  async function searchData(searchInput) {
    const response = await generateGetCall({
      apiPath: `${API_ENDPOINT_NAME.GET_ADMIN_CATEGORY}?searchTerm=${searchInput}`,
    });
    if (response.success) {
      dispatch(storeAllCategory(response.response.data.categoriesWithDishes));
    }
  }

  const pathCategoryName = useParams()?.categoryName;

  useEffect(() => {
    console.log('searchInput', searchInput);
    if (searchInput) {
      searchData(searchInput);
    }
    console.log('pathCategoryName', pathCategoryName);
    if (!pathCategoryName) {
      setCategory(null);
    } else {
      if (!category) {
        setCategory({
          categoryName: pathCategoryName,
        });
      }
    }
  }, [pathCategoryName, searchInput]);

  return (
    <>
      {!addItemOpener && (
        <SearchComponent
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setCategory={setCategory}
          // searchDataState={searchDataState}
        />
      )}
      {!category
        ? !addItemOpener
          ? categories && (
              <CategoryIndex
                setEditCategory={setEditCategory}
                setAddItemOpener={setAddItemOpener}
                isadmin={true}
                categoryIndexData={categories}
                setCategory={setCategory}
              />
            )
          : null
        : pathCategoryName &&
          !addItemOpener && (
            <ItemMenuIndex
              isadmin={true}
              category={category}
              setCategory={setCategory}
              categoryList={categories}
              setAddItemOpener={setAddItemOpener}
              setInitialSelectedState={setInitialSelectedState}
            />
          )}
      <SideMenuButton
        menu={categories}
        setCategory={setCategory}
        isAdmin={true}
      />
      {/* </div> */}
      {addItemOpener || EditCategory ? (
        <AddItemIndex
          EditCategory={EditCategory}
          setEditCategory={setEditCategory}
          addItemOpener={addItemOpener}
          setAddItemOpener={setAddItemOpener}
          initialSelectedState={initialSelectedState}
        />
      ) : null}
    </>
  );
};

export default AdminIndex;
