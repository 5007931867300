import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles/components/itemMenuIndex/itemMenuIndex.module.scss';
import useDynamicHeight from '../../utils/useDynamicHeight';
import FilterMenuIndex from './FilterMenuIndex';
import MenuSections from './MenuSections';
import LogoHeader from '../Header/Header';
import SearchComponent from '../searchComponent/SearchComponent';
import { useLocation } from 'react-router-dom';

const ItemMenuIndex = ({
  category,
  setCategory,
  categoryList,
  isadmin,
  setAddItemOpener,
  setInitialSelectedState,
}) => {
  const myElementRef = useRef(null);
  const heightElementRef = useRef(null);
  const location = useLocation();
  const isAdminFoodRoute = location.pathname.includes('/admin');
  const dynamicHeight = useDynamicHeight(heightElementRef, isAdminFoodRoute);
  const [searchInput, setSearchInput] = useState(null);
  const [toggleStates, setToggleStates] = useState({});
  const [scrollTrigger, setScrollTrigger] = useState(0); // New state to trigger scrolling
  const scrollbarStyles = useMemo(
    () => ({
      overflowY: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      display: 'flex',
      gap: '1.5rem',
      flexDirection: 'column',
      paddingBottom: '1.5rem',
    }),
    []
  );

  useEffect(() => {
    // Set all toggle states to true on the first load
    if (categoryList) {
      const initialToggleStates = {};
      categoryList.forEach((item, index) => {
        initialToggleStates[index] = true;
      });
      setToggleStates(initialToggleStates);
    }
  }, [categoryList]); // Run only when categoryList changes

  useEffect(() => {
    if (myElementRef.current && categoryList) {
      const targetItem = categoryList.find(
        (item) => item.categoryName === category?.categoryName
      );
      if (targetItem) {
        const targetIndex = categoryList.indexOf(targetItem);
        const targetElement = myElementRef.current.children[targetIndex];
        if (targetElement) {
          setTimeout(() => {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }, 100); // Delaying by 100 milliseconds
        }
      }
    }
  }, [scrollTrigger, categoryList]); // Update dependencies to include scrollTrigger

  useEffect(() => {
    setScrollTrigger((prev) => prev + 1); // Increment scrollTrigger whenever category changes
  }, [category]);

  return (
    <section
      className={styles.ItemMenuIndex_main}
      style={{ maxHeight: dynamicHeight }}
      ref={heightElementRef}
    >
      <FilterMenuIndex setcategoryList={setCategory} />
      <div style={scrollbarStyles} ref={myElementRef}>
        {categoryList &&
          categoryList?.map((item, index) => (
            <MenuSections
              key={index}
              name={item?.categoryName}
              list={item?.dishes}
              category={item}
              isadmin={isadmin}
              toggle={toggleStates[index]}
              setAddItemOpener={setAddItemOpener}
              setInitialSelectedState={setInitialSelectedState}
              setToggle={() =>
                setToggleStates((prevToggleStates) => ({
                  ...prevToggleStates,
                  [index]: !prevToggleStates[index],
                }))
              }
            />
          ))}
      </div>
    </section>
  );
};

export default ItemMenuIndex;
