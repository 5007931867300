import { useEffect, useState } from 'react';

const useDynamicHeight = (heightRef, NoFooter) => {
  const [dynamicHeight, setDynamicHeight] = useState('auto');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const calculatedHeight =
      window.innerHeight -
      heightRef?.current?.getBoundingClientRect()?.top -
      7.2 * (NoFooter ? 0 : 12);
    setDynamicHeight(calculatedHeight - 10 + 'px');
  }, [windowWidth, heightRef, NoFooter]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dynamicHeight;
};

export default useDynamicHeight;
