import React from 'react';
import styles from '../../styles/components/cart/discountList.module.scss';
const DiscountList = ({ item, seletedDiscount, setSelectedDiscount }) => {
  return (
    <div className={styles.discountList_item_main_wrapper} onClick={() => setSelectedDiscount(item)}>
      <span className={styles.title}>{item.title}</span>
      <button className={seletedDiscount?._id === item?._id ? styles.applied_btn : styles.apply_btn}>
        {seletedDiscount?._id === item?._id ? 'Applied' : 'Apply'}
      </button>
    </div>
  );
};

export default DiscountList;
