import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AddOrderToServer } from './orderThunks.js'; // Update the import path as needed
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../utils/generalFunction.js';

const initialState = {
  order: [],
  instructions: null,
  totalPrice: 0,
  loading: false,
  placedOrder: [],
};

const saveOrderToLocalStorage = (order) => {
  setLocalStorageItem('order', order);
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addToOrder: (state, action) => {
      const { dishId, ...rest } = action.payload;
      const existingItemIndex = state.order.findIndex(
        (item) => item.dishId === dishId
      );
      if (existingItemIndex !== -1) {
        // If item already exists, increase quantity
        state.order[existingItemIndex].quantity += 1;
      } else {
        // If item doesn't exist, add it to the order array
        state.order.push({ dishId, quantity: 1, ...rest });
      }

      saveOrderToLocalStorage(state.order);
    },

    updateQuantity: (state, action) => {
      const { dishId, quantity } = action.payload;
      const existingItemIndex = state.order.findIndex(
        (item) => item.dishId === dishId
      );
      if (existingItemIndex !== -1) {
        state.order[existingItemIndex].quantity = quantity;
      }
      // if quantity is 0, remove the item from the order
      if (quantity === 0) {
        state.order = state.order.filter((item) => item.dishId !== dishId);
      }

      saveOrderToLocalStorage(state.order);
    },

    updateOrderFromLocalStorage: (state) => {
      const order = getLocalStorageItem('order');
      if (order) {
        state.order = order;
      }
    },

    editCustomizedItem: (state, action) => {
      return {
        ...state,
        order: action.payload,
      };
    },

    addInstuctionToOrder: (state, action) => {
      return {
        ...state,
        instructions: action.payload,
      };
    },
    totalPriceOfOrder: (state, action) => {
      return {
        ...state,
        totalPrice: action.payload,
      };
    },
    addToPlacedOrder: (state, action) => {
      // Add the new placed order to the existing array
      const updatedPlacedOrder = [...state.placedOrder, action.payload];

      // Update local storage with the new array
      setLocalStorageItem('placedOrder', updatedPlacedOrder);

      // Update state immutably
      state.placedOrder = updatedPlacedOrder;
    },

    updatePlacedOrderFromLocalStorage: (state) => {
      const placedOrder = getLocalStorageItem('placedOrder');
      if (placedOrder) {
        state.placedOrder = placedOrder;
      }
    },

    resetOrder: (state, action) => {
      setLocalStorageItem('order', []);
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddOrderToServer.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddOrderToServer.fulfilled, (state, action) => {
        state.loading = false;
        // No need to handle order update here since it's done within the thunk if API succeeds
      })
      .addCase(AddOrderToServer.rejected, (state) => {
        state.loading = false;
        // Optionally handle error state or messages here
      });
  },
});

const selectOrderSlice = (state) => state.order;

// Define a selector function to get the orders from the order slice
export const selectOrders = createSelector(
  [selectOrderSlice],
  (orderSlice) => orderSlice.order
);

export const getOrderInstructions = createSelector(
  [selectOrderSlice],
  (orderSlice) => orderSlice.instructions
);
export const getCompleteOrder = createSelector(
  [selectOrderSlice],
  (orderSlice) => ({
    order: orderSlice.order,
    instructions: orderSlice.instructions,
  })
);

export const getPlaceOrder = createSelector(
  [selectOrderSlice],
  (orderSlice) => orderSlice.placedOrder
);
export const {
  addToOrder,
  editCustomizedItem,
  addInstuctionToOrder,
  totalPriceOfOrder,
  addToPlacedOrder,
  resetOrder,
  updateOrderFromLocalStorage,
  updateQuantity,
} = orderSlice.actions;

export default orderSlice.reducer;
