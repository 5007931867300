import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/admin/addItemIndex.module.scss';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/components/common/closeIcon.svg';
import { ReactComponent as AddIconOrange } from '../../assets/admin/addIconOrange.svg';
import uploadIcon from '../../assets/admin/upload.svg';
import AddItemPopper from './AddItemPopper';
import { generateGetCall } from '../../api_calls/generateGetCall';
import { generatePostCall } from '../../api_calls/generatePostCall';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import MySelectComponent from './CreateAble';
import { useDispatch } from 'react-redux';
import { storeAllCategory, updateCategory } from '../../store/categorySlice';

const InputBox = ({
  label,
  type,
  placeholder,
  value,
  name,
  onchange,
  error,
}) => {
  return (
    <label>
      <h3>{label}</h3>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onchange}
        style={
          error && error.field === name ? { border: '1px solid red' } : null
        }
      />
    </label>
  );
};

const ToggleComponent = ({ nonVeg, setNonVeg }) => {
  const toggleVeg = () => {
    setNonVeg(!nonVeg);
  };

  const vegCircle = {
    border: '1px solid #83C980',
  };
  const nonVegCircle = {
    border: '1px solid #F00',
  };

  const barVeg = {
    background: '#83C980',
  };
  const barNonVeg = {
    background: '#F00',
  };

  const handleToggle = (e) => {
    e.preventDefault();
  };
  return (
    <label onClick={handleToggle} className={styles.admin_toggle_veg_nonVeg}>
      <button onClick={toggleVeg} style={nonVeg ? barNonVeg : barVeg}>
        <span
          style={nonVeg ? nonVegCircle : vegCircle}
          className={nonVeg ? styles.nonVegCircle : styles.vegCircle}
        ></span>
      </button>
    </label>
  );
};

const InputQuantity = ({
  index,
  quantity,
  price,
  handleInputChange,
  quantityError,
  priceError,
  setFoodPriceQuantity,
  setFormData,
}) => {
  return (
    <div className={styles.input_wrapper_double_box_quantity}>
      <input
        name={`quantity-${index}`}
        type="text"
        placeholder="Portion Size"
        value={quantity}
        onChange={(e) => handleInputChange(e, index, 'portionSize')}
        style={{ borderColor: quantityError ? 'red' : '' }}
      />
      <input
        name={`quantityPrice-${index}`}
        type="number"
        placeholder="AUD Price"
        value={price}
        onChange={(e) => handleInputChange(e, index, 'price')}
        style={{ borderColor: priceError ? 'red' : '' }}
      />

      {index !== 0 && (
        <CloseIcon
          className={styles.primaryColorStroke + ' ' + styles.cursorPointer}
          onClick={(e) => {
            e.preventDefault();
            setFoodPriceQuantity((cur) => cur - 1);
            setFormData((prevFormData) => {
              const newFoodPrice = [...prevFormData.foodPrice];
              newFoodPrice.splice(index, 1);
              return { ...prevFormData, foodPrice: newFoodPrice };
            });
          }}
        />
      )}
    </div>
  );
};

const InputCustomizable = ({
  index,
  veg,
  name,
  price,
  handleInputChange,
  nameError,
  priceError,
  setCustomizable,
  setFormData,
}) => {
  return (
    <div className={styles.input_wrapper_double_box_quantity}>
      {/* <ToggleComponent
        nonVeg={!veg}
        setNonVeg={(e) => {
          setFormData((prevFormData) => {
            const newCustomizableItems = [...prevFormData.customizableItems];
            newCustomizableItems[index].veg = !veg;
            return { ...prevFormData, customizableItems: newCustomizableItems };
          });
        }}
      /> */}
      <input
        name={`customizableName-${index}`}
        type="text"
        placeholder="Customizable Name"
        value={name}
        onChange={(e) => handleInputChange(e, index, 'name')}
        style={{ borderColor: nameError ? 'red' : '' }}
      />
      <input
        name={`customizablePrice-${index}`}
        type="number"
        placeholder="AUD Price"
        value={price}
        onChange={(e) => handleInputChange(e, index, 'price')}
        style={{ borderColor: priceError ? 'red' : '' }}
      />

      <CloseIcon
        className={styles.primaryColorStroke + ' ' + styles.cursorPointer}
        onClick={(e) => {
          e.preventDefault();
          setCustomizable((cur) => cur - 1);

          setFormData((prevFormData) => {
            const newCustomizableItems = [...prevFormData.customizableItems];
            newCustomizableItems.splice(index, 1);
            return {
              ...prevFormData,
              customizableItems: newCustomizableItems,
            };
          });
        }}
      />
    </div>
  );
};

const DoubleInputQuantityBox = ({
  Customizable,
  foodPrice,
  handleInputChange,
  errors,
  setFoodPriceQuantity,
  setFormData,
}) => {
  // console.log('foodPrice', foodPrice);
  // console.log('foodPrice Customizable', Customizable);
  return (
    <div className={styles.doubleInputBox_wrapper}>
      <header>
        <h3>Food Price</h3>
      </header>
      {Array.from({ length: Customizable }, (_, index) => (
        <InputQuantity
          key={index}
          index={index}
          quantity={foodPrice[index]?.portionSize || ''}
          price={foodPrice[index]?.price || ''}
          handleInputChange={handleInputChange}
          quantityError={errors[index]?.quantity}
          priceError={errors[index]?.price}
          setFoodPriceQuantity={setFoodPriceQuantity}
          setFormData={setFormData}
        />
      ))}
    </div>
  );
};

const DoubleInputCustomizableBox = ({
  Customizable,
  customizableItems,
  handleInputChange,
  errors,
  setCustomizable,
  setFormData,
}) => {
  // console.log('customizableItems', customizableItems);
  // console.log('Customizable', Customizable);

  return (
    <div className={styles.doubleInputBox_wrapper}>
      <header>
        <h3>Customizable Items</h3>
      </header>
      {Array?.from({ length: Customizable }, (_, index) => (
        <InputCustomizable
          key={index}
          index={index}
          veg={customizableItems[index]?.veg}
          name={customizableItems[index]?.name || ''}
          price={customizableItems[index]?.price || ''}
          handleInputChange={handleInputChange}
          nameError={errors[index]?.name}
          priceError={errors[index]?.price}
          setCustomizable={setCustomizable}
          setFormData={setFormData}
        />
      ))}
    </div>
  );
};

const initialState = {
  categoryNames: [],
  categoryFile: '',
  dishName: '',
  foodPrice: [{ quantity: '', price: '' }],
  customizableItems: [{ name: '', price: '', veg: true }],
  description: '',
  veg: true,
  dishImage: null,
  bestseller: false,
};

const menuUploadState = {
  file: null,
};

const AddItemIndex = ({
  EditCategory,
  setEditCategory,
  addItemOpener,
  setAddItemOpener,
  initialSelectedState,
}) => {
  const [nonVeg, setNonVeg] = useState(false);
  const [popper, setPopper] = useState(false);
  // const [formData, setFormData] = useState(
  //   initialSelectedState || initialState
  // );

  // console.log('initialSelectedState', initialSelectedState);

  const [formData, setFormData] = useState(
    initialSelectedState
      ? JSON.parse(JSON.stringify(initialSelectedState))
      : initialState
  );

  const [formMenuUpload, setFormMenuUpload] = useState(menuUploadState);
  const [Customizable, setCustomizable] = useState(1);
  const [foodPriceQuantity, setFoodPriceQuantity] = useState(1);
  const [CategoryIndexData, setCategoryIndexData] = useState();
  const [categoryData, setCategoryData] = useState({
    ...EditCategory,
    file: null,
  });
  const [formType, setFormType] = useState('manually'); //menuUpload or manually
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [inputErrors, setInputErrors] = useState([]);

  async function fetchData() {
    const response = await generateGetCall({
      apiPath: `${API_ENDPOINT_NAME.GET_ADMIN_CATEGORY}`,
    });
    if (response.success) {
      // console.log(response.response.data.categoriesWithDishes);
      setCategoryIndexData(response.response.data.categoriesWithDishes);
      dispatch(storeAllCategory(response.response.data.categoriesWithDishes));
    }
  }
  useEffect(() => {
    console.log('initialSelectedState', initialSelectedState);
    fetchData();
  }, []);

  const handlePopper = () => {
    if (EditCategory) {
      setEditCategory(false);
    } else {
      setPopper(true);
    }
  };
  const mainRef = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      veg: nonVeg ? 'NON_VEG' : 'VEG',
    }));
  }, [nonVeg]);

  const handleFileChange = (e) => {
    console.log('handleFileChange');
    if (e.target.type === 'file') {
      console.log(e.target.files);
      console.log(e.target.name);
      const { name } = e.target;
      setFormData({ ...formData, [name]: e.target.files });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('formData', formData);
    if (initialSelectedState) {
      const response = await generatePostCall({
        apiPath: API_ENDPOINT_NAME.EDIT_ITEM,
        data: formData,
        isFile: true,
      });
      if (response.success) {
        setAddItemOpener(false);
        fetchData();
        // dispatch(updateDishInCategory(response.response.data.item));
        // window.location.reload();
      }
    } else {
      // console.log('dsdsdsd 11', formData);
      const response = await generatePostCall({
        apiPath: API_ENDPOINT_NAME.ADD_ITEM,
        data: formData,
        isFile: true,
      });
      if (response.success) {
        setAddItemOpener(false);
        fetchData();
      } else {
        setError(response.message);
      }
    }
  };
  const handleCategoryChange = (categoryNames) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      categoryNames: categoryNames, // Update the categoryNames in the form data
    }));
  };

  const handleCategoryData = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  const handleFileChangeForCategory = (e) => {
    console.log('handleFileChangeForCategory');
    if (e.target.type === 'file') {
      console.log(e.target.files);
      console.log(e.target.name);
      const { name } = e.target;
      setCategoryData({ ...categoryData, [name]: e.target.files });
    }
  };

  const handleChangeCategory = async (e) => {
    e.preventDefault();
    const response = await generatePostCall({
      apiPath: API_ENDPOINT_NAME.EDIT_CATEGORY,
      data: categoryData,
      isFile: true,
    });
    if (response.success) {
      console.log();
      setEditCategory(false);
      dispatch(updateCategory(response.response.data.category));
    }
  };

  const handleFileChangeForMenuUpload = (e) => {
    if (e.target.type === 'file') {
      const { name } = e.target;
      setFormMenuUpload({ ...formMenuUpload, [name]: e.target.files });
    }
  };

  const handleSubmitForMenuUpload = async (e) => {
    e.preventDefault();
    if (!formMenuUpload.file) {
      alert('Please select a file');
      return console.log('No file selected');
    }
    const response = await generatePostCall({
      apiPath: API_ENDPOINT_NAME.UPLOAD_MENU,
      data: formMenuUpload,
      isFile: true,
      fileVariables: ['file'],
    });
    if (response?.success) {
      // setFormMenuUpload(menuUploadState);
      alert('Menu Uploaded Successfully');
      setAddItemOpener(false);
    } else {
      alert(response?.message);
    }
  };

  const handleQuantityInputChange = (e, index, field) => {
    const value = e.target.value;
    setFormData((prevFormData) => {
      // Deep copy the foodPrice array and its objects
      const newFoodPrice = prevFormData.foodPrice.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return { ...item };
      });

      // If the index doesn't exist, add a new object
      if (index >= newFoodPrice.length) {
        newFoodPrice.push({ portionSize: '', price: '' });
        newFoodPrice[index][field] = value;
      }

      return { ...prevFormData, foodPrice: newFoodPrice };
    });

    setInputErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (!newErrors[index]) {
        newErrors[index] = { portionSize: false, price: false };
      }
      newErrors[index][field] = false;
      return newErrors;
    });
  };
  const handleCustomizableInputChange = (e, index, field) => {
    const value = e.target.value;
    setFormData((prevFormData) => {
      // Deep copy the customizableItems array and its objects
      const newCustomizableItems = prevFormData.customizableItems.map(
        (item, idx) => {
          if (idx === index) {
            return { ...item, [field]: value };
          }
          return { ...item };
        }
      );

      // If the index doesn't exist, add a new object
      if (index >= newCustomizableItems.length) {
        newCustomizableItems.push({ name: '', price: '' });
        newCustomizableItems[index][field] = value;
      }

      return { ...prevFormData, customizableItems: newCustomizableItems };
    });

    setInputErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (!newErrors[index]) {
        newErrors[index] = { name: false, price: false };
      }
      newErrors[index][field] = false;
      return newErrors;
    });
  };

  const handleBestSeller = (e) => {
    e.preventDefault();
    setFormData((prevFormData) => ({
      ...prevFormData,
      bestseller: !prevFormData.bestseller, // Toggle the bestseller property in the form data
    }));
  };

  useEffect(() => {
    // console.log('formData', formData);
    setFoodPriceQuantity(formData?.foodPrice?.length || 1);
    setCustomizable(formData?.customizableItems?.length || 1);
  }, [formData]);

  return (
    <>
      <div ref={mainRef} className={styles.addItemIndex_main}>
        <header>
          <div className={styles.formInfo}>
            <BackArrow
              onClick={() => handlePopper()}
              className={styles.primaryColorFill}
            />

            <h4>{EditCategory ? 'Edit Category' : 'Add Item'}</h4>
          </div>
          <button
            onClick={() => {
              setFormType(
                formType === 'menuUpload' ? 'manually' : 'menuUpload'
              );
            }}
          >
            {formType === 'menuUpload' ? 'Manually' : 'Menu Upload'}
          </button>
        </header>

        {formType === 'menuUpload' ? (
          <form>
            <label className={styles.file_menu}>
              <input
                type="file"
                name="file"
                onChange={handleFileChangeForMenuUpload}
                multiple
              />
              <div className={styles.submit_wrapper}>
                {formMenuUpload.file &&
                Object.keys(formMenuUpload.file).length > 0 ? (
                  Object.keys(formMenuUpload.file).map((key, index) => (
                    <div key={index}>{formMenuUpload.file[key].name}</div>
                  ))
                ) : (
                  <>
                    <img src={uploadIcon} alt="uploadIcon" />
                    <p>
                      Click Here to upload <br />
                      Upload Good Quality Image
                    </p>
                  </>
                )}
              </div>
            </label>
            <div className={styles.button_wrapper}>
              <button>Cancel</button>
              <button onClick={handleSubmitForMenuUpload}>Save</button>
            </div>
          </form>
        ) : addItemOpener ? (
          <form encType="multipart/form-data">
            <label>
              <h3>Category Name</h3>
              <MySelectComponent
                CategoryIndexData={CategoryIndexData}
                styles={styles}
                onCategoryChange={handleCategoryChange}
                onFileChange={handleFileChange}
                formData={formData}
                defaultValueVar={initialSelectedState}
              />
            </label>
            <InputBox
              label="Food Name"
              placeholder="Food Name "
              type="text"
              name="dishName"
              value={formData.dishName}
              onchange={handleInputChange}
              error={error}
            />

            <DoubleInputQuantityBox
              Customizable={foodPriceQuantity}
              foodPrice={formData.foodPrice}
              handleInputChange={handleQuantityInputChange}
              errors={inputErrors}
              setFoodPriceQuantity={setFoodPriceQuantity}
              setFormData={setFormData}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setFoodPriceQuantity((cur) => cur + 1);
              }}
              className={styles.add_customizable_items}
            >
              Add more Portion
              <AddIconOrange className={styles.primaryColorFill} />
            </button>

            <div className={styles.admin_toggle_wrapper}>
              Veg
              <ToggleComponent nonVeg={nonVeg} setNonVeg={setNonVeg} />
              Non-Veg
            </div>
            <label className={styles.admin_form_textarea}>
              <header>
                <h3>Food Description</h3> <p>(Not More than 20 Words*)</p>
              </header>
              <textarea
                placeholder="Text here..."
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                style={
                  error && error.field === 'description'
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </label>
            <DoubleInputCustomizableBox
              Customizable={Customizable}
              customizableItems={formData.customizableItems}
              handleInputChange={handleCustomizableInputChange}
              errors={inputErrors}
              setCustomizable={setCustomizable}
              setFormData={setFormData}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setCustomizable((cur) => cur + 1);
              }}
              className={styles.add_customizable_items}
            >
              Add more customizable item{' '}
              {/* <img src={addIconOrange} alt="addIconOrange" /> */}
              <AddIconOrange className={styles.primaryColorFill} />
            </button>
            <InputBox
              label="GST %"
              placeholder="GST %"
              type="number"
              name="gstTax"
              value={formData.gstTax}
              onchange={handleInputChange}
              error={error}
            />

            <div className={styles.best_seller_wrapper}>
              <h3>Bestseller</h3>
              <button onClick={handleBestSeller}>
                <span
                  className={
                    formData.bestseller
                      ? `${styles.bestsellerActive}`
                      : `${styles.bestseller_notActive}`
                  }
                ></span>
                <div
                  className={`${styles.toggle_bar} ${
                    formData.bestseller
                      ? styles.bestseller_toggle_bar_Active
                      : styles.bestseller_toggle_bar_notActive
                  }`}
                ></div>
              </button>
            </div>
            <div className={styles.upload_image_wrapper}>
              <h3>Add Food Image</h3>
              <label>
                <input
                  type="file"
                  name="dishImage"
                  onChange={handleFileChange}
                  multiple
                />
                <div className={styles.submit_wrapper}>
                  {formData.dishImage &&
                  Object.keys(formData.dishImage).length > 0 ? (
                    Object.keys(formData.dishImage).map((key, index) => (
                      <div key={index}>{formData.dishImage[key].name}</div>
                    ))
                  ) : (
                    <>
                      <img src={uploadIcon} alt="uploadIcon" />
                      <p>
                        Click Here to upload <br />
                        Upload Good Quality Image
                      </p>
                    </>
                  )}
                </div>
              </label>
            </div>
            <div className={styles.button_wrapper}>
              <button>Cancel</button>
              <button onClick={handleSubmit}>Save</button>
            </div>
          </form>
        ) : (
          <form>
            <InputBox
              label="Category"
              placeholder="Category Name"
              type="text"
              name="categoryNames"
              value={categoryData.categoryNames}
              onchange={handleCategoryData}
              error={error}
            />
            <label className={styles.file_menu}>
              <input
                type="file"
                name="file"
                onChange={handleFileChangeForCategory}
                multiple
              />
              <div className={styles.submit_wrapper}>
                {categoryData.file &&
                Object.keys(categoryData.file).length > 0 ? (
                  Object.keys(categoryData.file).map((key, index) => (
                    <div key={index}>{categoryData.file[key].name}</div>
                  ))
                ) : (
                  <>
                    <img src={uploadIcon} alt="uploadIcon" />
                    <p>
                      Click Here to upload <br />
                      Upload Good Quality Image
                    </p>
                  </>
                )}
              </div>
            </label>
            <div className={styles.button_wrapper}>
              <button>Cancel</button>
              <button onClick={handleChangeCategory}>Save</button>
            </div>
          </form>
        )}
        {popper ? (
          <AddItemPopper
            setAddItemOpener={setAddItemOpener}
            mainRef={mainRef}
            styles={styles}
            setPopper={setPopper}
          />
        ) : null}
      </div>
      asd
    </>
  );
};

export default AddItemIndex;
