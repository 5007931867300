import React, { useEffect } from 'react';
import styles from '../../styles/components/footer.module.scss';
import cartIcon from '../../assets/components/footer/cartIcon.svg';
import { ReactComponent as HamBurger } from '../../assets/components/footer/hamBurger.svg';
import footerHome from '../../assets/components/footer/footerHome.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Footer = ({ dineIn, restaurantId, tableNumber }) => {
  const navigate = useNavigate();
  const orders = useSelector((state) => state.order);

  // const { tableNumber } = useParams();
  const handleRoute = () => {
    if (dineIn) {
      navigate(`/${restaurantId}/dineIn/${tableNumber}/cart`);
    }
  };

  useEffect(() => {
    console.log('orders', orders);
  }, [orders]);

  return (
    <>
      <footer className={styles.footer_main}>
        {/* <img className={styles.smallIcons} src={hamBurger} alt="hamBurger" /> */}
        <div className={styles.svg_footer_wrapper}>
          <HamBurger
            className={styles.primaryColorFill + ' ' + styles.smallIcons}
          />
        </div>
        <div
          className={styles.home_footer_wrapper}
          onClick={() =>
            navigate(`/${restaurantId}/dineIn/${tableNumber}/orders`)
          }
        >
          <img src={footerHome} alt="footerHome" />
        </div>
        <div className={styles.cart_footer_wrapper}>
          <span className={styles.cartNumber}>
            {orders?.order ? orders?.order?.length : ''}
          </span>
          <img
            onClick={handleRoute}
            className={styles.smallIcons}
            src={cartIcon}
            alt="cartIcon"
          />
        </div>
      </footer>
    </>
  );
};

export default Footer;
