import React, { useState, useEffect } from 'react';
import styles from '../../styles/takeAway/orderItemCard.module.scss';
import vegIcon from '../../assets/components/common/VegItem.svg';
import noVegIcon from '../../assets/components/common/NonVegItem.svg';
import statusPaid from '../../assets/order/statusPaid.png';
import statusCancelled from '../../assets/order/statusCancelled.png';
import { ReactComponent as ActiveCooking } from '../../assets/chefIcon/ActiveCooking.svg';
import { ReactComponent as Cooking } from '../../assets/chefIcon/Cooking.svg';
import { ReactComponent as DishIcon } from '../../assets/chefIcon/DishIcon.svg';
import { ReactComponent as ActiveDishIcon } from '../../assets/chefIcon/ActiveDishIcon.svg';
import serving from '../../assets/waitersIcon/serving.svg';
import { ReactComponent as ProfileIcon } from '../../assets/admin/profile.svg';
import { ReactComponent as ActiveServing } from '../../assets/waitersIcon/activeServing.svg';
import { generatePostCall } from '../../api_calls/generatePostCall';
import { API_ENDPOINT_NAME } from '../../utils/constants';

const OrderItem = ({
  styles,
  item,
  history,
  waiter,
  waiterIcon,
  orderList,
}) => {
  const [list, setList] = useState(false);
  return (
    <div className={styles.OrderItem_wrapper_div}>
      <div className={styles.order_header}>
        <span>
          <img src={item.isVeg ? vegIcon : noVegIcon} alt="vegOrNonvegIcon" />{' '}
          {item.name} x {item.quantity}
        </span>
        {item?.price && <strong>AUD {item.price}</strong>}
        {item?.status && !history && !waiter ? (
          <div className={styles.cookingOrCooked_wrapper}>
            {item.status === 'cooking' ? (
              <ActiveCooking className={styles.primaryColor} />
            ) : (
              <Cooking className={styles.primaryColor} />
            )}
            {item.status === 'cooked' ? (
              <ActiveDishIcon className={styles.primaryColor} />
            ) : (
              <DishIcon className={styles.primaryColor} />
            )}
          </div>
        ) : (
          item?.status &&
          waiter && (
            <>
              <img src={serving} alt="serving" />
            </>
          )
        )}
        {waiterIcon && <ActiveServing className={styles.primaryColorFill} />}
      </div>
      <span className={styles.customised_Items_wrapper}>
        <small>GST {item?.gstTax}%</small>
      </span>
      <ul className={styles.customised_Items_wrapper_list}>
        {list
          ? item?.customizedItem
              .filter((customizedItem) => customizedItem.checked)
              .map((list, index) => (
                <li key={index}>
                  <img src={vegIcon} alt="vegIcon" />
                  {list.name}
                </li>
              ))
          : null}
      </ul>
      {orderList?.paymentStatus === 'PAID' && (
        <div className={styles.statusPaid}>
          <img src={statusPaid} alt="statusPaid" />
        </div>
      )}
      {orderList?.status === 'CANCELLED' && (
        <div className={styles.statusPaid}>
          <img src={statusCancelled} alt="statusCancelled" />
        </div>
      )}
      <div className={styles.horizontal_rulling}></div>
    </div>
  );
};

const OrderItemCard = ({
  orderList,
  history,
  waiter,
  waiterIcon,
  isUser = true,
}) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    if (
      !orderList?.createdAt ||
      orderList.isActive === false ||
      orderList.status === 'PAID'
    )
      return;

    const calculateElapsedTime = () => {
      const createdAt = new Date(orderList.createdAt);
      const now = new Date();
      const diff = Math.max(0, now - createdAt); // Ensure no negative value

      const seconds = Math.floor(diff / 1000) % 60;
      const minutes = Math.floor(diff / (1000 * 60)) % 60;
      const hours = Math.floor(diff / (1000 * 60 * 60));

      let timeString = '';
      if (hours > 0) timeString += `${hours}H : `;
      if (minutes > 0) timeString += `${minutes}M : `;
      if (seconds > 0) timeString += `${seconds}SEC`; // Show seconds only if no hours
      setElapsedTime(timeString);
    };

    calculateElapsedTime();
    const intervalId = setInterval(calculateElapsedTime, 100);
    return () => clearInterval(intervalId);
  }, [orderList?.createdAt]);

  const statusStages = ['Placed', 'Preparing', 'Ready', 'Served'];
  const [currentStatus, setCurrentStatus] = useState('Served');

  const getProgressWidth = (status) => {
    const index = statusStages.indexOf(status);
    if (index === -1) return '0%';
    return `${(index / (statusStages.length - 1)) * 100}%`;
  };

  const cancelOrder = async (tableId) => {
    try {
      const response = await generatePostCall({
        apiPath: API_ENDPOINT_NAME.MARK_ORDER_AS_CANCEL,
        data: {
          tableId: tableId,
          orderId: orderList?._id,
        },
      });
      if (response.success) {
        // setTable(null);
        alert('Order cancelled');
      }
    } catch (err) {
      console.log('Error - cancelOrder');
    }
  };
  return (
    <div
      className={
        styles.orderPage_card_wrapper +
        ' ' +
        (orderList?.status === 'CANCELLED' ? styles.CANCELLED : '')
      }
    >
      <header className={styles.OrderItemCard_header}>
        <span>
          Table No. <span>{orderList?.tableNumber}</span>
        </span>
        <span>
          Token No. <span>{orderList?.token}</span>
        </span>
      </header>

      {/* Elapsed Time */}

      {elapsedTime && (
        <div className={styles.elapsedTime}>
          <strong>Elapsed Time:</strong> <span>{elapsedTime}</span>
        </div>
      )}

      {/* Progress Bar */}
      <div className={styles.progressContainer}>
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{ width: getProgressWidth(currentStatus) }}
          ></div>
        </div>
        <div className={styles.progressStages}>
          {statusStages.map((stage, index) => (
            <span
              key={index}
              className={currentStatus === stage ? styles.activeStage : ''}
            >
              {stage}
            </span>
          ))}
        </div>
      </div>

      {orderList?.items?.map((item, index) => (
        <OrderItem
          styles={styles}
          key={index}
          item={item}
          history={history}
          waiter={waiter}
          waiterIcon={waiterIcon}
          orderList={orderList}
        />
      ))}

      <div className={styles.details}>
        <div className={styles.time}>
          <span>{new Date(orderList?.createdAt).toLocaleDateString()}</span>
          <span>{new Date(orderList?.createdAt).toLocaleTimeString()}</span>
        </div>
        <div className={styles.price}>
          {orderList?.totalPrice && (
            <strong>AUD {orderList?.totalPrice}</strong>
          )}
        </div>
      </div>
      <small className={styles.total_price_toolpit}>
        Includes all taxes and charges
      </small>

      {orderList?.instructions && (
        <div className={styles.instructions_div}>
          <h3>Added instructions</h3>
          <p>{orderList?.instructions}</p>
        </div>
      )}

      {orderList?.employeeName && (
        <div className={styles.footerAction}>
          <div className={styles.orderAssignedTo}>
            <ProfileIcon
              className={
                styles.primaryColorFill + ' ' + styles.profileIcon_header
              }
            />
            <span>{orderList?.employeeName}</span>
          </div>

          {!isUser && (
            <button
              className={styles.cancelOrderButton}
              onClick={() => cancelOrder(orderList?.tableId)}
            >
              Cancel this order
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderItemCard;
