import Axios from '../axios';

const generatePostCall = async ({
  apiPath,
  data,
  urlId = null,
  isFile = false,
  fileVariables = ['categoryImage', 'dishImage'], // Default file variable names
  isPut = false,
}) => {
  let header = {
    Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    'content-type': 'application/json',
  };

  if (isFile) {
    let tempData = data;
    delete tempData.categoryFile;
    data = new FormData();

    Object.keys(tempData).forEach((key) => {
      fileVariables.forEach((fileVar) => {
        if (
          key.startsWith(fileVar) &&
          tempData[key] &&
          tempData[key].length > 0
        ) {
          const filesObj = tempData[key];
          for (let i = 0; i < filesObj.length; i++) {
            data.append(key, filesObj[i]);
          }
          delete tempData[key];
        }
      });
    });

    // Append the rest of the fields to the FormData
    Object.keys(tempData).forEach((key) => {
      const value = tempData[key];
      if (
        Array.isArray(value) ||
        (typeof value === 'object' && value !== null)
      ) {
        data.append(key, JSON.stringify(value));
      } else {
        data.append(key, value);
      }
    });

    // Set the header for file uploads
    header['Content-Type'] = 'multipart/form-data';
  }

  const config = {
    method: isPut ? 'put' : 'post',
    maxBodyLength: Infinity,
    url: apiPath + (urlId ? '/' + urlId : ''),
    headers: header,
    data: data,
  };

  try {
    const response = await Axios.request(config);

    if (response.status === 200 || response.status === 201) {
      return {
        success: true,
        message: response.data.message,
        response: response,
      };
    } else if (response.status === 701) {
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('userType');
    } else {
      return {
        success: false,
        message: response.data.message,
        response: response,
      };
    }
  } catch (error) {
    console.log('Error  ', error);

    if (error.response && error.response.status === 701) {
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('userType');
    }

    return {
      success: false,
      message: error.response?.data?.message || 'Internal server error',
      key: error.response?.data?.key || '',
    };
  }
};

export { generatePostCall };
