import React from 'react';

import styles from '../../styles/homePage/homePage.module.scss';
import homePageFiller from '../../assets/homePage/homepageFiller.svg';
import HomeCard from './HomeCard';
import takeAwayIcon from '../../assets/homePage/takeAway.svg';
import dineInIcon from '../../assets/homePage/dineIn.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

const HomePage = () => {
  console.log('HomePage');
  const navigate = useNavigate();
  const restaurantId = useParams().restaurantId || '';
  const handleTakeAwayClick = useCallback(() => {
    navigate(`/${restaurantId}/takeaway`);
  }, [navigate]);

  const handleDineInClick = useCallback(() => {
    navigate(`/${restaurantId}/dinein`);
  }, [navigate]);

  const myStyles = useMemo(
    () => ({
      marginRight: '0.5rem',
    }),
    []
  );

  const restaurantPublicInfo = useSelector(
    (state) => state.restaurantPublicInfo
  );

  // console.log(useParams().restaurantId);

  return (
    <>
      <div className={styles.homePage_wrapper}>
        <div className={styles.homePage}>
          {/* <img
            className={styles.homePageFiller}
            src={homePageFiller}
            loading="lazy"
            alt="homepageFiller"
          /> */}

          {/* {restaurantPublicInfo?.restaurantLogoBlobUrl && (
            <img
              src={restaurantPublicInfo?.restaurantLogoBlobUrl}
              className={styles.logo}
              alt="logo"
            />
          )} */}

          <h2 className={styles.homePage_heading}>What brings you here?</h2>
          <div className={styles.homePage_card_wrapper}>
            <HomeCard
              passFunction={handleDineInClick}
              key={2}
              title="Dine In"
              image={dineInIcon}
              alt="DineInIcon"
            />
            <HomeCard
              passFunction={handleTakeAwayClick}
              key={1}
              style={myStyles}
              title="Take Away"
              image={takeAwayIcon}
              alt="TakeAwayIcon"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
