import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/cart/billingCard.module.scss';
import { ReactComponent as DownArrow } from '../../../src/assets/components/common/colorDropDown.svg';
import { useDispatch } from 'react-redux';
import { totalPriceOfOrder } from '../../store/orderSlice';

const BillingCard = ({ orders }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  // Calculate total price and total GST amount
  const { totalPrice, totalGST } = orders.reduce(
    (acc, order) => {
      const orderPrice = order.price * order.quantity || 0; // Base price of the order

      // Calculate customized item price if available
      const customizedTotal =
        order.customizedItem?.reduce((subtotal, item) => {
          if (item.checked) {
            return subtotal + (item.price || 0); // Add price if item is checked
          }
          return subtotal;
        }, 0) || 0;

      // Calculate addons total price if available
      const addonsTotal =
        order.addons?.reduce((subtotal, addon) => {
          return subtotal + (addon.price || 0);
        }, 0) || 0;

      // Total price for the current order (base + customized + addons)
      const totalOrderPrice = orderPrice + customizedTotal + addonsTotal;

      // Calculate GST amount for the current order
      const gstTax = order.gstTax || 0; // GST rate for the order, default to 0 if not provided
      const gstAmount = (totalOrderPrice * gstTax) / 100;

      // Accumulate the total price (including GST) and total GST amount
      return {
        totalPrice: acc.totalPrice + totalOrderPrice + gstAmount, // Include GST in the final price
        totalGST: acc.totalGST + gstAmount, // Sum up GST amounts
      };
    },
    { totalPrice: 0, totalGST: 0 } // Initial accumulator values
  );

  // Dispatch totalPriceOfOrder after calculating totalPrice
  useEffect(() => {
    dispatch(totalPriceOfOrder(totalPrice));
  }, [dispatch, totalPrice]);

  return (
    <>
      <div className={styles.BillingCard_main}>
        <header>
          <h3>Billing</h3>

          <DownArrow
            className={styles.primaryColorStroke}
            style={show ? { transform: 'rotate(180deg)' } : {}}
            onClick={() => setShow((prevShow) => !prevShow)}
          />
        </header>
        <div className={styles.bill_container}>
          {show ? (
            <div className={styles.order_list}>
              {orders.map((item, index) => (
                <div key={index} className={styles.order_item}>
                  <span>
                    {item.dishName} (<strong>{item.quantity}</strong> Qty){' '}
                  </span>
                  <span>AUD {item.price * item.quantity || 0}</span>{' '}
                  {/* Ensure price is valid */}
                </div>
              ))}
              <div className={styles.order_item}>
                <span>Total GST</span>
                <span>AUD {totalGST.toFixed(2)}</span> {/* Display total GST */}
              </div>
            </div>
          ) : null}
          <div className={styles.total_bill}>
            <h4>Total Amount (including GST)</h4>
            <span>AUD {totalPrice.toFixed(2)}</span>{' '}
            {/* Display total price with GST */}
          </div>
          <footer>Includes GST & charges*</footer>
        </div>
      </div>
    </>
  );
};

export default BillingCard;
