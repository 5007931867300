import React, { useRef, useState } from 'react';
import LogoHeader from '../../components/Header/Header';
import AdminSideBar from '../adminFood/AdminSideBar';
import SearchComponent from '../../components/searchComponent/SearchComponent';
import EmployeeCard from './EmployeeCard';
import useDynamicHeight from '../../utils/useDynamicHeight';
import { useNavigate } from 'react-router-dom';

const EmployeeDisplay = ({
  data,
  styles,
  sideBar,
  setSideBar,
  setAddEmp,
  setSelectedProfile,
}) => {
  const [searchInput, setSearchInput] = useState();
  const myElementRef = useRef(null);
  const dynamicHeight = useDynamicHeight(myElementRef, true);
  const navigate = useNavigate();

  const handleAddNewEmployee = () => {
    setAddEmp(true);
    setSelectedProfile(false);
    navigate('add');
  };

  return (
    <>
      <div className={styles.AdminIndex_main_container}>
        <SearchComponent
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <div className={styles.add_new_emp_header}>
          <span> Employee </span>
          <button onClick={handleAddNewEmployee}>Add Employee +</button>
        </div>
        <div
          className={styles.employee_card_wrapper}
          ref={myElementRef}
          style={{ maxHeight: dynamicHeight }}
        >
          {data &&
            data.map((item, index) => (
              <EmployeeCard
                item={item}
                key={index}
                setSelectedProfile={setSelectedProfile}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default EmployeeDisplay;
