import React, { useState } from 'react';
import styles from '../../styles/components/cart/orderItem.module.scss';
import vegIcon from '../../assets/components/common/VegItem.svg';
import nonvegIcon from '../../assets/components/common/NonVegItem.svg';
import addIcon from '../../assets/components/common/addIcon.svg';
import { ReactComponent as ColorDropDown } from '../../assets/components/common/colorDropDown.svg';
import { editCustomizedItem, updateQuantity } from '../../store/orderSlice';
import { useDispatch } from 'react-redux';

function addLeadingZero(number) {
  if (number < 10) {
    number = '0' + number;
  }
  return number;
}

const OrderItem = ({ data, setOrders, orders }) => {
  const [quantity, setQuantity] = useState(data.quantity);
  const [showCustomized, setShowCustomized] = useState(false);
  const dispatch = useDispatch();
  const handleQuantityChange = (newQuantity) => {
    // if (newQuantity <= 0) {
    //   setOrders((prevOrders) =>
    //     prevOrders.filter((order) => order.dishId !== data.dishId)
    //   );
    // }

    dispatch(updateQuantity({ dishId: data.dishId, quantity: newQuantity }));
    setQuantity(newQuantity);
  };

  const changeCheckBoxValue = (e, customizedItem, _id) => {
    const { checked } = e.target;
    const data = {
      customizedItem,
      checked,
      _id,
    };
    const updatedOrders = orders.map((order) => {
      if (order._id === data._id) {
        const updatedCustomizedItem = order.customizedItem.map((cat) => {
          if (cat.name === customizedItem.name) {
            return { ...cat, checked: checked };
          }
          return cat;
        });
        return { ...order, customizedItem: updatedCustomizedItem };
      }
      return order;
    });
    setOrders(updatedOrders);
    dispatch(editCustomizedItem(data));
  };

  const getCustomPrice = () => {
    const totalPrice = data?.customizedItem?.reduce((total, item) => {
      if (item.checked) {
        return total + item.price;
      }
      return total;
    }, 0);

    return totalPrice || 0;
  };

  return (
    <>
      <div className={styles.orderItem_main}>
        <div className={styles.orderItem_wrapper}>
          <div className={styles.orderItem_part_one}>
            <span>
              <img
                src={data.isVeg ? vegIcon : nonvegIcon}
                alt={data.dishType ? 'vegIcon' : 'nonvegIcon'}
              />
              {data.dishName}
            </span>

            <p>
              Customized Items
              {/* <img onClick={() => setShowCustomized(curr => !curr)} src={colorDropDown} alt="colorDropDown" /> */}
              <ColorDropDown
                className={styles.primaryColorStroke}
                onClick={() => setShowCustomized((curr) => !curr)}
              />
            </p>
          </div>
          <div className={styles.orderItem_part_two}>
            <div className={styles.orderItem_button_wrapper}>
              <button onClick={() => handleQuantityChange(quantity - 1)}>
                -
              </button>
              <span>{addLeadingZero(quantity)}</span>
              <button onClick={() => handleQuantityChange(quantity + 1)}>
                <img src={addIcon} alt="addIcon" />{' '}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.customizedItem}>
          {showCustomized
            ? data.customizedItem?.map((item, index) => {
                return (
                  <span key={index}>
                    <span>
                      <img src={vegIcon} alt="vegIcon" />
                      {item.name}
                    </span>
                    <span>
                      AUD {item.price}{' '}
                      <input
                        type="checkbox"
                        onChange={(e) => changeCheckBoxValue(e, item, data._id)}
                        checked={item.checked}
                      />
                    </span>
                  </span>
                );
              })
            : null}
        </div>
        <div className={styles.price_wrapper}>
          <span>
            {' '}
            <strong>AUD {data.price}</strong>
          </span>
          <span>
            {' '}
            <strong>AUD {data.price * quantity + getCustomPrice()}</strong>
          </span>
        </div>
        <div className={styles.horizontal_rulling}></div>
      </div>
    </>
  );
};
export default OrderItem;
