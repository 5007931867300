import React, { useEffect, useState } from 'react';
import styles from '../../styles/employee/addNewEmployee.module.scss';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
import upload from '../../assets/components/common/upload.svg';
import pdfIcon from '../../assets/components/common/pdfIcon.svg';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useNavigate, useParams } from 'react-router-dom';
import { generatePostCall } from '../../api_calls/generatePostCall';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { generateGetCall } from '../../api_calls/generateGetCall';
import { getFileData } from '../../api_calls/getFileData';
import { ReactComponent as ProfileIcon } from '../../assets/admin/profile.svg';

const newForm = {
  role: '',
  name: '',
  number: '',
  email: '',
  // address: '',
  USER_IMAGE: null,
  DOCUMENT: [],
};

const InputBox = ({ item, formData, setFormData, editable }) => {
  const handleChange = (e) => {
    setFormData((info) => ({
      ...info,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.add_new_employee_input_wrapper}>
      <span>{item.label}</span>
      <input
        value={formData[item.name]}
        onChange={handleChange}
        type={item.type}
        name={item.name}
        placeholder={item.placeholder}
        disabled={!editable}
      />
    </div>
  );
};

const TextArea = ({ item, formData, setFormData, editable }) => {
  const handleChange = (e) => {
    setFormData((info) => ({
      ...info,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.add_new_employee_textArea_wrapper}>
      <span>
        {item.label}
        <span className={styles.subtext_wrapper}>{item?.subtext}</span>
      </span>
      <textarea
        value={formData[item.name]}
        onChange={handleChange}
        name={item.name}
        placeholder={item.placeholder}
        disabled={!editable}
      ></textarea>
    </div>
  );
};

const ImageArea = ({ item, formData, setFormData }) => {
  const handleChange = (e) => {
    setFormData((info) => ({
      ...info,
      [e.target.name]: item.multiple ? [...e.target.files] : e.target.files,
    }));
  };

  const isNullOrUndefinedOrEmptyArray = (value) => {
    return (
      value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    );
  };

  return (
    <div className={styles.add_new_employee_img_wrapper}>
      <span>
        {item.label}
        <span>{item.optionaltext}</span>
        <span className={styles.subtext_wrapper}>{item?.subtext}</span>
      </span>
      <label>
        <input
          onChange={handleChange}
          name={item.name}
          type={item.type}
          style={{ opacity: 0, height: '0px' }}
          accept={item.accept}
          multiple={item.multiple}
        />
        {isNullOrUndefinedOrEmptyArray(formData[item.name]) ? (
          <>
            <img src={upload} alt="upload" />
            <span>{item.labelImgOne}</span>
            <span>{item.labelImgTwo}</span>
          </>
        ) : (
          <>
            {Array.isArray(formData[item.name])
              ? formData[item.name].map((file, index) => (
                  <span key={index}>{file.name}</span>
                ))
              : formData[item.name]?.name || ''}
          </>
        )}
      </label>
    </div>
  );
};

const Checkbox = ({ item, editable }) => {
  return (
    <div className={styles.add_new_employee_checkBox_wrapper}>
      <span>{item.label}</span>
      <input type={item.type} style={{ opacity: 0 }} disabled={!editable} />
    </div>
  );
};

const FormGenerator = ({ item, formData, setFormData, editable }) => {
  return (
    <>
      {item.type === 'text' ||
      item.type === 'number' ||
      item.type === 'email' ? (
        <InputBox
          item={item}
          formData={formData}
          setFormData={setFormData}
          editable={editable}
        />
      ) : item.type === 'textarea' ? (
        <TextArea
          item={item}
          formData={formData}
          setFormData={setFormData}
          editable={editable}
        />
      ) : item.type === 'checkbox' ? (
        <Checkbox item={item} editable={editable} />
      ) : item.type === 'dropdown' ? (
        <AsyncCreatableSelect
          cacheOptions
          defaultOptions
          isDisabled={!editable}
          loadOptions={(inputValue) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve(
                  item.options.filter((i) =>
                    i.label.toLowerCase().includes(inputValue.toLowerCase())
                  )
                );
              }, 1000);
            })
          }
          onChange={(selectedValues) => {
            setFormData((info) => ({
              ...info,
              [item.name]:
                item.name === 'role'
                  ? selectedValues.value
                  : selectedValues.map((option) => option.value),
            }));
          }}
          value={
            item.name === 'role'
              ? item.options.find((option) => option.value === formData.role) ||
                null
              : formData[item.name]?.map((value) =>
                  item.options.find((option) => option.value === value)
                ) || []
          }
        />
      ) : (
        <ImageArea item={item} formData={formData} setFormData={setFormData} />
      )}
    </>
  );
};

const AddNewEmployee = ({ setAddEmp }) => {
  const [formData, setFormData] = useState(newForm);
  const [formStructure, setFormStructure] = useState([
    {
      label: 'Role',
      name: 'role',
      placeholder: 'Select Role',
      type: 'dropdown',
      options: [
        { value: 'CHEF', label: 'CHEF' },
        { value: 'WAITER', label: 'WAITER' },
      ],
    },
    {
      label: 'Name',
      name: 'name',
      placeholder: 'Employee Name ',
      type: 'text',
    },
    {
      label: 'Mobile',
      name: 'number',
      placeholder: 'Enter Mobile  (will be used for login)',
      type: 'number',
    },
    {
      label: 'Email',
      name: 'email',
      placeholder: 'Enter Email  (will be used for login)',
      optionaltext: ' (optional)',
      type: 'email',
    },
    // {
    //   label: 'Address',
    //   name: 'address',
    //   placeholder: 'Text here...',
    //   type: 'textarea',
    //   subtext: '(Not More than 200 Words*)',
    // },
  ]);

  const newEmpAddionalFields = [
    {
      label: 'Add Employee Image',
      name: 'USER_IMAGE',
      type: 'file',
      labelImgOne: 'Click Here to upload',
      labelImgTwo: 'Upload Good Quality Image',
      accept: 'image/*',
    },
    {
      label: 'Add Employee Documents ',
      name: 'DOCUMENT',
      type: 'file',
      optionaltext: ' (if any)',
      subtext: '(pdf, image)',
      labelImgOne: 'Click Here to upload',
      labelImgTwo: 'Upload Good Quality Image',
      accept: 'image/*, pdf/*',
      multiple: true,
    },
  ];

  const [editable, setEditable] = useState(false);
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const [isVisible, setIsVisible] = useState(window.innerWidth > 480);
  const [profileImage, setProfileImage] = useState('');
  const [isActive, setIsActive] = useState(null);

  const pullEmployeeData = async (empId) => {
    try {
      const response = await generateGetCall({
        apiPath: API_ENDPOINT_NAME.GET_EMPLOYEE_DETAILS,
        pathId: empId,
      });

      let data = response.response.data.data;
      console.log('data --- >', data);

      setFormData({
        role: data.role,
        name: data.name,
        number: data.number,
        email: data.email,
        address: data.address,
        USER_IMAGE: null,
        DOCUMENT: [],
      });

      setIsActive(data.active);

      let responseBlob = await getFileData({
        apiPath: API_ENDPOINT_NAME.DOWNLOAD_EMPLYOEE_ASSET,
        data: {
          userId: empId,
          assetId: data?.userImg?.assetId,
          assetType: data?.userImg?.fieldName,
        },
      });

      if (responseBlob) {
        let url = URL.createObjectURL(responseBlob.fileBlob);
        setProfileImage(url);
        console.log('url', url);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };
  useEffect(() => {
    const updateFormStructure = () => {
      if (employeeId && employeeId !== 'add') {
        pullEmployeeData(employeeId);
        setFormStructure((prev) => prev.slice(0, 5));
        setEditable(false);
      } else {
        setFormStructure((prev) => [...prev, ...newEmpAddionalFields]);
        setEditable(true);
      }
    };
    updateFormStructure();
  }, [employeeId]);

  const handleSubmitForm = async () => {
    let formDataCopy = { ...formData };
    Object.keys(formDataCopy).forEach(
      (key) =>
        (formDataCopy[key] === null ||
          formDataCopy[key] === undefined ||
          (Array.isArray(formDataCopy[key]) &&
            formDataCopy[key].length === 0)) &&
        delete formDataCopy[key]
    );

    const response = await generatePostCall({
      apiPath:
        employeeId !== 'add'
          ? API_ENDPOINT_NAME.EDIT_EMPLOYEE
          : API_ENDPOINT_NAME.ADD_EMPLOYEE,
      data: formDataCopy,
      isFile: true,
      urlId: employeeId !== 'add' ? employeeId : null,
      fileVariables: ['USER_IMAGE', 'DOCUMENT'],
      isPut: employeeId !== 'add' ? true : false,
    });

    if (response.success) navigate(-1);
    else alert(response.message);
  };

  const lockUnlockEmployee = async (empId, status) => {
    try {
      const response = await generatePostCall({
        apiPath: API_ENDPOINT_NAME.LOCK_UNLOCK_EMPLOYEE,
        data: {
          isActive: status,
        },
        urlId: empId,
        isPut: true,
      });

      if (response.success) {
        // pullEmployeeData(empId);
        navigate(-1);
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error('Error locking/unlocking employee:', error);
    }
  };

  const deleteEmployee = async (empId) => {
    try {
      const response = await generatePostCall({
        apiPath: API_ENDPOINT_NAME.DELETE_EMPLOYEE,
        urlId: empId,
        isPut: true,
      });

      if (response.success) {
        navigate(-1);
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };

  return (
    <>
      <div className={styles.add_new_employee}>
        <header
          onClick={() => {
            navigate(-1);
          }}
        >
          <BackArrow className={styles.primaryColorStroke} />

          <span>Employee</span>
        </header>
        {!editable && (
          <div className={styles.profile_viewer}>
            {profileImage ? (
              <img
                className={styles.existing_profile_pic}
                src={profileImage}
                alt="employeeId"
              ></img>
            ) : (
              <ProfileIcon className={styles.existing_profile_pic} />
            )}

            <div
              className={styles.delete_blockAccess_wrapper}
              style={{
                position: isVisible ? 'absolute' : 'relative',
                // display: isVisible ? 'flex' : 'none',
              }}
            >
              {
                <button
                  onClick={() => {
                    lockUnlockEmployee(employeeId, !isActive);
                  }}
                  className={isActive ? '' : styles.unblock_access}
                >
                  {isActive ? 'Block Access' : 'Unblock Access'}
                </button>
              }

              <button
                onClick={() => {
                  deleteEmployee(employeeId);
                }}
              >
                Delete Account
              </button>
            </div>
          </div>
        )}

        {formStructure.map((item, index) => (
          <FormGenerator
            key={index}
            item={item}
            formData={formData}
            setFormData={setFormData}
            editable={editable}
          />
        ))}
        {editable ? (
          <div className={styles.add_new_emp_button_wraper}>
            <button onClick={() => setAddEmp(false)}>Cancel</button>
            <button onClick={handleSubmitForm}>Save</button>
          </div>
        ) : (
          <div className={styles.add_new_emp_button_wraper}>
            <button onClick={() => setAddEmp(false)}>Back</button>
            <button
              onClick={() => {
                setEditable(true);
                setFormStructure((prev) => [...prev, ...newEmpAddionalFields]);
              }}
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default AddNewEmployee;
