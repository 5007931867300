import React, { useCallback, useEffect, useState } from 'react';
import styles from '../../styles/dineIn/dineInIndex.module.scss';
import SearchComponent from '../../components/searchComponent/SearchComponent';
import CategoryIndex from '../../components/category/CategoryIndex';
import Footer from '../../components/footer/Footer.js';
import ItemMenuIndex from '../../components/category/ItemMenuIndex.js';
import SideMenuButton from '../../components/sideMenuButton/SideMenuButton.js';
import { generateGetCall } from '../../api_calls/generateGetCall.js';
import { API_ENDPOINT_NAME } from '../../utils/constants.js';
import { useDispatch } from 'react-redux';
import {
  getAllCategories,
  storeAllCategory,
} from '../../store/categorySlice.js';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateOrderFromLocalStorage } from '../../store/orderSlice.js';

const DineInIndex = () => {
  const [searchInput, setSearchInput] = useState(null);
  const [category, setCategory] = useState(null);
  const categories = useSelector(getAllCategories);
  const dispatch = useDispatch();
  const { restaurantId } = useParams();
  const { tableNumber } = useParams();
  const [initialSelectedState, setInitialSelectedState] = useState(null);

  async function fetchData() {
    try {
      const response = await generateGetCall({
        apiPath: `${API_ENDPOINT_NAME.GET_CATEGORY}?restaurantId=${restaurantId}`,
      });
      if (response.success) {
        // setCategoryIndexData(response.response.data.categoriesWithDishes);
        dispatch(storeAllCategory(response.response.data.categoriesWithDishes));
      } else {
        console.error('API Call succeeded but returned an error:', response);
      }
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  }

  const pathCategoryName = useParams()?.categoryName;
  // const [searchDataState, setsearchDataState] = useState();

  async function searchData(searchInput) {
    const response = await generateGetCall({
      apiPath: `${API_ENDPOINT_NAME.GET_ADMIN_CATEGORY}?searchTerm=${searchInput}`,
    });
    if (response.success) {
      // setsearchDataState(response.response.data.data);
      dispatch(storeAllCategory(response.response.data.categoriesWithDishes));
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    console.log('searchInput', searchInput);
    if (searchInput) {
      searchData(searchInput);
    }
    console.log('pathCategoryName', pathCategoryName);
    if (!pathCategoryName) {
      setCategory(null);
    } else {
      if (!category) {
        setCategory({
          categoryName: pathCategoryName,
        });
      }
    }
    dispatch(updateOrderFromLocalStorage());
  }, [pathCategoryName, searchInput]);

  return (
    <>
      <div className={styles.DineInIndex_wrapper_file}>
        <div className={styles.DineInIndex_main}>
          <SearchComponent
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            setCategory={setCategory}
            // searchDataState={searchDataState}
          />

          {!category ? (
            categories &&
            categories.length > 0 && (
              <CategoryIndex
                categoryIndexData={categories}
                setCategory={setCategory}
                isadmin={false}
              />
            )
          ) : (
            <ItemMenuIndex
              isadmin={false}
              category={category}
              setCategory={setCategory}
              categoryList={categories}
              setInitialSelectedState={setInitialSelectedState}
            />
          )}
        </div>
        <SideMenuButton menu={categories} setCategory={setCategory} />
        <Footer
          dineIn={true}
          restaurantId={restaurantId}
          tableNumber={tableNumber}
        />
      </div>
    </>
  );
};
export default DineInIndex;
