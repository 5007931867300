import React from 'react';

import styles from '../../styles/homePage/homeCard.module.scss';
import windLeftIcon from '../../assets/homePage/leftWindIcon.svg';
import windRightIcon from '../../assets/homePage/rightWindIcon.svg';


const HomeCard = React.memo(
  ({ title, image, alt, style, passFunction }) => {
    return (<>
      <div onClick={() => passFunction()} className={styles.homeCard_main}>
        {title}
        <div className={`${ styles.windLeftIcon_wrapper } ${ !style ? styles.secondBoxLeft : '' }`}>
          <img src={windLeftIcon} alt="windIcon" loading="lazy" />
          <img src={windRightIcon} alt="windIcon" loading="lazy" />
        </div>
        <img src={image} alt={alt} style={style} loading="lazy"/>
        <div className={`${ styles.windRightIcon_wrapper } ${ !style ? styles.secondBoxRight : '' }`}>
          <img src={windLeftIcon} alt="windIcon" loading="lazy" />
          <img src={windRightIcon} alt="windIcon" loading="lazy" />
        </div>
      </div>
    </>);
  });

HomeCard.displayName = 'HomeCard';

export default HomeCard;
