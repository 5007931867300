// import Axios from 'axios';
import Axios from '../axios';

const generateGetCall = async ({
  page,
  search = '',
  limit = 30,
  apiPath,
  additionalData = {},
  pathId = '',
}) => {
  let data = {
    page: page,
    limit: limit,
  };

  if (Object.keys(additionalData).length > 0) {
    data = { ...data, ...additionalData };
  }

  if (search !== '') {
    data.search = search;
  }

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: apiPath + (pathId ? '/' + pathId : ''),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
    },
    params: data,
  };

  try {
    const response = await Axios.request(config);

    if (
      (response.status === 200 || response.status === 201) &&
      response.data.success === true
    ) {
      return {
        success: true,
        message: response.data.message,
        response: response,
      };
    } else {
      return {
        success: false,
        message: response.data.message,
        response: response,
      };
    }
  } catch (error) {
    console.log('Error generateGetCall ', apiPath, error);

    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      localStorage.removeItem('accessToken');
    }

    return {
      success: false,
      message: 'Something went wrong',
    };
  }
};

export { generateGetCall };
