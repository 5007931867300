import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OrderItemCard from './OrderItemCard';
import styles from '../../styles/components/order.module.scss';
import BillingItemCard from './BillingItemCard';
import { generatePostCall } from '../../api_calls/generatePostCall';
import { API_ENDPOINT_NAME } from '../../utils/constants';

const BillingPage = ({
  orderState,
  isEmployee,
  q_restaurantId,
  q_tableNumber,
  setTable = () => {},
}) => {
  const navigate = useNavigate();
  const { restaurantId, tableNumber } = useParams();

  const [allOrders, setAllOrders] = useState([]);

  useEffect(() => {
    if (orderState) {
      // Combine all orders into one array
      let allOrdersItems = [];
      let totalPrice = 0;
      orderState.forEach((order) => {
        allOrdersItems.push(...order.items);
        totalPrice += order.totalPrice;
      });

      // Combine items with the same dishId
      const combinedItems = allOrdersItems.reduce((acc, item) => {
        const existingItemIndex = acc.findIndex(
          (accItem) => accItem.dishId === item.dishId
        );

        if (existingItemIndex !== -1) {
          // If the item already exists, increase the quantity and merge customizedItem
          acc[existingItemIndex].quantity += item.quantity;
          acc[existingItemIndex].customizedItem = [
            ...acc[existingItemIndex].customizedItem,
            ...item.customizedItem,
          ];
        } else {
          // If the item does not exist, add it to the accumulator
          acc.push({ ...item });
        }

        return acc;
      }, []);

      // Set the combined items to the state
      setAllOrders({
        items: combinedItems,
        totalPrice,
      });
    }
  }, [orderState]);

  const markAsPaid = async (tableId) => {
    const response = await generatePostCall({
      apiPath: API_ENDPOINT_NAME.MARK_ORDER_AS_PAID,
      data: {
        tableId: tableId,
      },
    });
    if (response.success) {
      // setTable(null);
      alert('Order marked as paid');
    }
  };

  const cancelOrder = async (tableId) => {
    const response = await generatePostCall({
      apiPath: API_ENDPOINT_NAME.MARK_ORDER_AS_CANCEL,
      data: {
        tableId: tableId,
      },
    });
    if (response.success) {
      // setTable(null);
      alert('Order cancelled');
    }
  };

  // console.log(orderState);

  return (
    <div className={styles.OrderPage_wrapper}>
      <div>
        <BillingItemCard orderList={allOrders} />
        {/* // <OrderItemCard orderList={orderState} /> */}
      </div>

      <div className={styles.navigation_button}>
        <button
          className={styles.cancel_button}
          onClick={() => {
            // setTable(null);
            cancelOrder(q_tableNumber);
          }}
        >
          Cancel Order
        </button>
        <button
          onClick={() => {
            markAsPaid(q_tableNumber);
          }}
        >
          Mark Paid
        </button>
      </div>
    </div>
  );
};

export default BillingPage;
