import React, { useEffect, useState } from 'react';
import { ReactComponent as BackArrow } from '../../assets/components/common/backArrow.svg';
// import logo from '../../assets/components/logo/Logo.svg';
import { ReactComponent as ProfileIcon } from '../../assets/admin/profile.svg';
import { ReactComponent as HamburgerIcon } from '../../assets/admin/hamBurgerIcon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/admin/logout.svg';
import styles from '../../styles/admin/Header.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLocalStorageItem } from '../../utils/generalFunction';

const Header = ({ show, setBack, admin, setSideBar, normalUser = false }) => {
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const restaurantPublicInfo = useSelector(
    (state) => state.restaurantPublicInfo
  );

  const handleLogout = () => {
    // remove everything from local storage except restaurantId
    let restaurantId = getLocalStorageItem('restaurantId');
    localStorage.clear();
    if (restaurantId) {
      localStorage.setItem('restaurantId', restaurantId);
      navigate('/' + restaurantId + '/login');
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <header className={styles.logoHeader}>
        {admin ? (
          <>
            <HamburgerIcon
              className={styles.primaryColorFill + ' ' + styles.cursorPointer}
              // fill="red"
              onClick={() => setSideBar(true)}
            />
            <span>
              {restaurantPublicInfo?.restaurantLogoBlobUrl && (
                <img
                  src={restaurantPublicInfo?.restaurantLogoBlobUrl}
                  className={styles.logo}
                  alt="logo"
                />
              )}
            </span>
          </>
        ) : null}
        {show ? (
          <BackArrow
            className={styles.primaryColorFill + ' ' + styles.cursorPointer}
            onClick={() => setBack(null)}
          />
        ) : null}

        {admin ? (
          <div className={styles.profileIcon_wrapper}>
            <ProfileIcon
              className={
                styles.primaryColorFill + ' ' + styles.profileIcon_header
              }
              onClick={() => setDropdown(!dropdown)}
            />

            {!normalUser && (
              <div
                className={
                  styles.profile_dropdown +
                  ' ' +
                  (dropdown ? styles.active : '')
                }
              >
                {/* <span className={styles.profile_dropdown_item}>
                  <ProfileIcon className={styles.primaryColorFill} />
                  Profile
                </span> */}
                <span
                  className={styles.profile_dropdown_item}
                  onClick={handleLogout}
                >
                  <LogoutIcon
                    className={
                      styles.primaryColorStroke + ' ' + styles.primaryColorFill
                    }
                  />
                  Logout
                </span>
              </div>
            )}
          </div>
        ) : null}
      </header>
    </>
  );
};

export default Header;
