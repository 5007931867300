// userSlice.js
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  role: null,
  restaurantId: null,
  waiterActive: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action) {
      console.log('userSlice payload', action.payload);
      state.isAuthenticated = true;
      state.role = action.payload.role;
      state.restaurantId = action.payload.restaurantId;
      state.userId = action.payload.userId;
      state.waiterActive = action.payload.waiterActive;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.role = null;
    },
  },
});

const userRole = (state) => state.user.role;

export const getUserRole = createSelector([userRole], (role) => role);

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
