import React from 'react';
import styles from '../../styles/components/categoryIndex/menuSections/menuSections.module.scss';
import dropDown from '../../assets/components/common/dropDown.svg';
import ItemCard from './ItemCard';

const MenuSections = ({
  name,
  list,
  setCategory,
  isadmin,
  toggle,
  setToggle,
  category,
  setAddItemOpener,
  setInitialSelectedState,
}) => {
  const handleToggle = () => {
    setToggle();
  };

  return (
    <div className={styles.MenuSections_main}>
      <header onClick={handleToggle}>
        <span>{name}</span>
        <img
          src={dropDown}
          alt="dropDown"
          style={toggle ? { transform: 'rotate(180deg)' } : null}
        />
      </header>
      {toggle && <div className={styles.horizontal_rulling}></div>}
      <div className={styles.menuSection_item_card}>
        {toggle &&
          list?.map((item, index) => (
            <ItemCard
              key={index}
              item={item}
              category={category}
              setCategory={setCategory}
              isadmin={isadmin}
              setAddItemOpener={setAddItemOpener}
              setInitialSelectedState={setInitialSelectedState}
            />
          ))}
      </div>
    </div>
  );
};

export default MenuSections;
