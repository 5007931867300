import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import CartIndex from './cart/CartIndex';
import styles from '../styles/admin/adminIndex.module.scss';
import LogoHeader from './Header/Header';
import AdminSideBar from '../views/adminFood/AdminSideBar';
import { pullRestaurantPublicInfo } from '../api_calls/pullRestaurantPublicInfo';
import { getLocalStorageItem } from '../utils/generalFunction';
import OrdersIndex from '../views/orderPage/OrdersIndex';
import DineInIndex from '../views/dineIn/DineInIndex';
import TakeAwayIndex from '../views/takeAway/TakeAwayIndex';
import HomePage from '../views/home/HomePage';
import AdminTables from '../views/adminTables/AdminTables';
import UserTablesPage from '../views/adminTables/UserTablesPage';
import UserTables from '../views/adminTables/UserTables';
// import { updatePageNumber } from '../../../redux/reducers/PageScrollSlice';

function User() {
  const adminFeatures = [
    {
      sectionName: 'home',
      url: '/home',
      component: <HomePage />,
    },
    {
      sectionName: 'takeaway',
      url: '/takeaway',
      component: <TakeAwayIndex />,
    },
    {
      sectionName: 'cart',
      url: '/takeaway/cart',
      component: <CartIndex />,
    },
    {
      sectionName: 'takeawayOrders',
      url: '/takeaway/orders',
      component: <OrdersIndex />,
    },
    {
      sectionName: 'dineIn',
      url: '/dineIn',
      component: <UserTables />,
    },
    {
      sectionName: 'dineIn',
      url: '/dineIn/:tableNumber/:categoryName?',
      component: <DineInIndex />,
    },
    {
      sectionName: 'dineInCart',
      url: '/dineIn/:tableNumber/cart',
      component: <CartIndex />,
    },
    {
      sectionName: 'dineInOrders',
      url: '/dineIn/:tableNumber/orders',
      component: <OrdersIndex />,
    },
  ];
  const [sideBar, setSideBar] = useState(false);

  const dispatch = useDispatch();
  const scroll = useSelector((state) => state.pageScroll);
  const restaurantId =
    useParams('restaurantId')?.restaurantId ||
    getLocalStorageItem('restaurantId') ||
    null;

  useEffect(() => {
    console.log('restaurantId', restaurantId);
    if (restaurantId) {
      pullRestaurantPublicInfo(dispatch, restaurantId);
    }
  }, [restaurantId]);

  const RedirectToHome = () => {
    return <Navigate to={'home'} />;
  };

  return (
    <>
      <div className={styles.AdminIndex_main_container_wrapper}>
        <div className={styles.AdminIndex_main_container}>
          {/* <NavigationBar /> */}
          <LogoHeader
            setSideBar={setSideBar}
            admin={true}
            styles={styles}
            normalUser={true}
          />

          <Routes>
            <Route path="/" element={<RedirectToHome />} />
            {adminFeatures.map((feature, index) => (
              <Route
                key={index}
                path={feature.url}
                element={feature.component}
              />
            ))}
          </Routes>
        </div>
      </div>
    </>
  );
}

export default User;
