import React from 'react';
import { ReactComponent as Slice } from '../../../assets/animation/pizzaSlice.svg';
import styles from '../../../styles/orderAnimation/orderAnimationTwo.module.scss';
const OrderAnimationTwo = () => {
  return (
    <>
      <div className={styles.OrderAnimationTwo_main}>
        {/* <img src={slice} alt='pizzaSlice' /> */}
        <Slice className={styles.primaryColor} />
      </div>
    </>
  );
};
export default OrderAnimationTwo;
