
import writeXlsxFile from 'write-excel-file';

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.error('Error setting local storage item:', err);
  }
};


export const getLocalStorageItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (err) {
    console.error('Error getting local storage item:', err);
    return null;
  }
};


export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate.replace(/\//g, '/');
};

// Function to format the time in AM/PM
export const formatTime = (timeString) => {
  const time = new Date(timeString);
  const options = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedTime = time.toLocaleTimeString('en-US', options);
  return formattedTime;
};


export const getLowestPrice = (prices) => {
  if (!Array.isArray(prices) || prices.length === 0) {
    return null; // or any appropriate value indicating no prices available
  }

  let lowestPrice = prices[0].price;

  for (let i = 1; i < prices.length; i++) {
    if (prices[i].price < lowestPrice) {
      lowestPrice = prices[i].price;
    }
  }
  return lowestPrice;
};


const createExcelHeader = (header) => {
  // console.log(header);
  if (header) {
    return Object.keys(header).map(key => ({
      value: key,
      fontWeight: 'bold'
    }));
  }
};


const createExcelData = (excelData) => {
  let EXCEL_DATA = [];
  excelData.map((data) => {
    const DATA_ROW = Object.keys(data).map((item) => {
      const value = data[item];
      return {
        type: typeof(value) === 'string' ? String : Number,
        value
      };
    });
    EXCEL_DATA.push(DATA_ROW);
  });

  return EXCEL_DATA;
};


export const createExcelSheet = async (excelData, restaurantName) => {
  const HEADER_ROW = createExcelHeader(excelData[0]);
  const EXCEL_DATA = createExcelData(excelData);
  const FILE_DATA = [HEADER_ROW, ...EXCEL_DATA];

  const report = await writeXlsxFile(FILE_DATA, {
    filePath: `${ restaurantName }_${ Date.now() }.xlsx`,
  });

  var a = document.createElement('a');
  a.href = URL.createObjectURL(report);
  a.download = `${ restaurantName }_${ Date.now() }.xlsx`;
  a.click();
};
