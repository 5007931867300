import React, { useEffect, useState } from 'react';
import styles from '../../styles/components/itemCard/itemCard.module.scss';
import NonVegItem from '../../assets/components/common/NonVegItem.svg';
import VegItem from '../../assets/components/common/VegItem.svg';
// import productImg from '../../assets/components/productImg/2151182540.png';
import NoDishImage from '../../assets/components/common/NoDishImage.svg';
import { ReactComponent as RatingIcon } from '../../assets/components/common/starIcon.svg';
import ItemDetails from './ItemDetails';
import deleteIcon from '../../assets/components/common/delete.svg';
import { ReactComponent as Line } from '../../assets/components/common/line.svg';
import { ReactComponent as Edit } from '../../assets/components/common/edit.svg';
import { useDispatch } from 'react-redux';
import { deleteDishFromCategory } from '../../store/categorySlice';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { generatePostCall } from '../../api_calls/generatePostCall';
import { ReactComponent as CloseIcon } from '../../assets/admin/closeIcon.svg';
import { editDishAvaiablity } from '../../store/categorySlice';
import {
  getLocalStorageItem,
  getLowestPrice,
} from '../../utils/generalFunction';
import { getFileData } from '../../api_calls/getFileData';

const DeletePopper = ({ setPoper, item }) => {
  const dispatch = useDispatch();

  const deleteCategory = async (e) => {
    e.stopPropagation();
    setPoper(true);
    const response = await generatePostCall({
      apiPath: `${API_ENDPOINT_NAME.DELETE_ITEM}`,
      data: { dishId: item._id },
    });
    if (response.success) {
      console.log(response);
      dispatch(deleteDishFromCategory(response.response.data.deleteItem));
      setPoper(false);
    }
  };

  return (
    <>
      <div className={styles.opening_popper}>
        <div className={styles.innerPopper}>
          <div className={styles.innerPopper_padding}>
            {/* <img
              onClick={(e) => setPoper(false)}
              src={closeIcon}
              alt="closeIcon"
            /> */}
            <CloseIcon
              className={styles.primaryColorStroke}
              onClick={(e) => setPoper(false)}
            />
            <p>Are you sure you want to delete this food ? </p>
            <div className={styles.button_wrapper_edit}>
              <button onClick={(e) => setPoper(false)}>Cancel</button>
              <button onClick={(e) => deleteCategory(e)}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ItemCard = ({
  item,
  isadmin,
  category,
  setAddItemOpener,
  setInitialSelectedState,
}) => {
  const [ItemPage, setItemPage] = useState(false);
  const [poper, setPoper] = useState(false);
  const dispatch = useDispatch();

  const handleEdit = (e) => {
    e.stopPropagation();
    setAddItemOpener(true);
    setInitialSelectedState({
      dishId: item._id,
      categoryId: category._id,
      // categoryName: category.categoryName,
      categoryFile: '',
      dishName: item.dishName,
      foodPrice: item.prices,
      customizableItems: item.customizableItems,
      description: item.description,
      // categoryIds: item.categoryIds,
      categoryNames: item.categoryIds.map((item) => item.name),
      file: null,
    });
  };
  // const dispatch = useDispatch();

  const handleDelete = async (e) => {
    e.stopPropagation();
    setPoper(true);
  };

  const enableORdisable = async (e, status) => {
    e.stopPropagation();
    const response = await generatePostCall({
      apiPath: API_ENDPOINT_NAME.ENABLE_OR_DISABLE_DISH,
      data: { dishId: item._id, available: status },
    });
    if (response.success) {
      console.log(response.response.data.dish);
      dispatch(editDishAvaiablity(response.response.data.dish));
    }
  };

  const getCategoryURL = async (
    restaurantId,
    categoryId,
    assetDetails,
    setDishImageURL
  ) => {
    setDishImageURL([]);
    assetDetails?.forEach(async (item) => {
      let responseBlob = await getFileData({
        apiPath: API_ENDPOINT_NAME.RESTAURANT_PUBLIC_IMAGE,
        data: {
          restaurantId: restaurantId,
          ...item,
          id: categoryId,
          assetType: 'DISH',
        },
      });

      if (responseBlob) {
        let url = URL.createObjectURL(responseBlob.fileBlob);
        console.log('url', url);
        setDishImageURL((prev) => {
          if (!prev.includes(url)) {
            return [...prev, url];
          }
          return prev;
        });
      }
    });
  };
  const [dishImageURL, setDishImageURL] = useState([]);

  useEffect(() => {
    // console.log('dish item', item);
    // console.log('item.categoryImages', item.categoryImages, item);
    if (item.dishImages) {
      getCategoryURL(
        getLocalStorageItem('restaurantId'),
        item._id,
        item.dishImages,
        setDishImageURL
      );
    }
  }, [item.dishImages]);

  return (
    <>
      <div className={styles.horizontal_rulling}></div>
      {!ItemPage && (
        <div
          className={
            styles.itemCard_main +
            (item.available ? '' : ' ' + styles.disable_item)
          }
        >
          <div className={styles.item_details}>
            <div className={styles.header}>
              <img
                src={item.dishType === 'VEG' ? VegItem : NonVegItem}
                alt="NonVegItem"
              />

              {item.bestseller && (
                <div className={styles.tag_wrapper}>
                  <span className={styles.tag_name}>Bestseller</span>
                </div>
              )}
            </div>
            <div className={styles.info}>
              <h2 className={styles.name}>{item.dishName}</h2>
              {item.rating ? (
                <div className={styles.rating_wrapper}>
                  <span className={styles.rating}>{item.rating}</span>

                  <RatingIcon className={styles.primaryColorFill} />
                </div>
              ) : null}
            </div>
            <div className={styles.price}>
              AUD {getLowestPrice(item.prices)}
            </div>
            <div className={styles.description}>{item.description}</div>
          </div>
          <div className={styles.itemIconAndButton}>
            {dishImageURL.length > 0 ? (
              // dishImageURL.map((url, index) => (
              //   <img
              //     src={url || NoDishImage}
              //     alt={item.name}
              //     loading="lazy"
              //     key={index}
              //   />
              // ))
              <img
                src={dishImageURL[0] || NoDishImage}
                alt={item.name}
                loading="lazy"
              />
            ) : (
              <img src={NoDishImage} alt={item.name} loading="lazy" />
            )}

            {isadmin && (
              <div className={styles.enable_disable_button_wrapper}>
                <button
                  onClick={(e) => {
                    enableORdisable(e, !item.available);
                  }}
                  className={item.available ? styles.disable : styles.enable}
                >
                  {item.available ? 'Disable' : 'Enable'}
                </button>
              </div>
            )}

            <button
              className={styles.addItem_button}
              style={item.available ? {} : { cursor: 'not-allowed' }}
              onClick={() => {
                if (item.available) {
                  setItemPage(true);
                } else {
                  alert('Item is not available');
                }
              }}
            >
              {isadmin ? (
                <>
                  <Edit
                    onClick={(e) => handleEdit(e)}
                    className={
                      styles.primaryColorFill + ' ' + styles.cursorPointer
                    }
                  />

                  <Line
                    className={
                      styles.primaryColorStroke + ' ' + styles.Edit_icons
                    }
                  />

                  <img
                    onClick={(e) => handleDelete(e)}
                    className={styles.Edit_icons + ' ' + styles.cursorPointer}
                    src={deleteIcon}
                    alt="delete"
                  />
                </>
              ) : (
                'Add Item'
              )}
            </button>
          </div>
        </div>
      )}
      {ItemPage ? (
        <ItemDetails
          productImg={dishImageURL[0] || NoDishImage}
          item={item}
          setItemPage={setItemPage}
        />
      ) : null}
      {poper ? <DeletePopper setPoper={setPoper} item={item} /> : null}
    </>
  );
};

export default ItemCard;
