import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from '../../../styles/orderAnimation/orderAnimationThree.module.scss';
import { ReactComponent as Pot1 } from '../../../assets/animation/pot1.svg';
import { ReactComponent as Pot2 } from '../../../assets/animation/pot2.svg';
import { ReactComponent as Pot3 } from '../../../assets/animation/pot3.svg';
import { ReactComponent as Pot4 } from '../../../assets/animation/pot4.svg';
import { ReactComponent as Pot5 } from '../../../assets/animation/pot5.svg';
import { ReactComponent as Pot6 } from '../../../assets/animation/pot6.svg';

const potImages = [Pot1, Pot2, Pot3, Pot4, Pot5, Pot6];

const preloadImages = () => {
  // potImages.forEach((image) => {
  //   const img = new Image();
  //   img.src = image;
  // });
  potImages.forEach((image) => {
    <image className={styles.primaryColor} />;
  });
};

const OrderAnimationThree = () => {
  const [currentPotIndex, setCurrentPotIndex] = useState(0);
  const animationRef = useRef(null);
  const lastTimestampRef = useRef(null);

  useEffect(() => {
    preloadImages();

    const animate = (timestamp) => {
      if (!lastTimestampRef.current) {
        lastTimestampRef.current = timestamp;
      }

      if (timestamp - lastTimestampRef.current >= 90) {
        setCurrentPotIndex((prevIndex) => (prevIndex + 1) % potImages.length);
        lastTimestampRef.current = timestamp;
      }

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  const animateCallback = useCallback((timestamp) => {
    if (!lastTimestampRef.current) {
      lastTimestampRef.current = timestamp;
    }

    if (timestamp - lastTimestampRef.current >= 90) {
      setCurrentPotIndex((prevIndex) => (prevIndex + 1) % potImages.length);
      lastTimestampRef.current = timestamp;
    }

    animationRef.current = requestAnimationFrame(animateCallback);
  }, []);

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animateCallback);
    return () => cancelAnimationFrame(animationRef.current);
  }, [animateCallback]);

  return (
    <div className={styles.OrderAnimationThree_main}>
      <img src={potImages[currentPotIndex]} alt="pot" />
    </div>
  );
};

export default OrderAnimationThree;
