import React from 'react';
import styles from '../../styles/searchComponent/searchComponent.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/components/searchComponent/Search.svg';

const SearchComponent = ({ check, setCheck, searchInput, setSearchInput }) => {
  return (
    <>
      <div className={styles.searchComponent_main}>
        <label className={styles.searchComp_label}>
          <input
            value={searchInput}
            onChange={(e) => {
              console.log(searchInput);
              console.log(e.target.value);
              setSearchInput(e.target.value);
              console.log('check', check);

              // setCheck(!check);
              // setCheck();
            }}
            type="text"
            placeholder="Search"
          />
          {/* <img src={SearchIcon} alt="SearchIcon" loading='lazy' /> */}
          <SearchIcon className={styles.primaryColorStroke} />
        </label>
      </div>
    </>
  );
};

export default SearchComponent;
