import React, { useState, useEffect, useRef } from 'react';
import styles from '../../styles/components/filterMenuIndex/filterMenuIndex.module.scss';
import { ReactComponent as FilterIcon } from '../../assets/components/menuIndex/filterIcon.svg';
import { ReactComponent as SortingIcon } from '../../assets/components/menuIndex/sortingIcon.svg';
import { ReactComponent as DownArrow } from '../../assets/components/common/colorDropDown.svg';
import { ReactComponent as CloseIcon } from '../../assets/admin/closeIcon.svg';
import NonVegItem from '../../assets/components/common/NonVegItem.svg';
import VegItem from '../../assets/components/common/VegItem.svg';
import bestsellersItem from '../../assets/components/common/bestsellerItem.svg';
import { generateGetCall } from '../../api_calls/generateGetCall';
import { API_ENDPOINT_NAME } from '../../utils/constants';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeAllCategory } from '../../store/categorySlice';

const FilterMenuIndex = ({ setcategoryList }) => {
  const [sortMenu, setSortMenu] = useState(false);
  const [filterMenu, setFilterMenu] = useState(false);
  const menuRef = useRef(null);
  const { restaurantID } = useParams();

  // Consolidated state for filters and sorting
  const [filters, setFilters] = useState({
    veg: false,
    nonVeg: false,
    bestsellers: false,
    lowToHigh: false,
    highToLow: false,
    goodRatings: false,
  });

  const initialRender = useRef(true);

  // Handle clicks outside the menu to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setSortMenu(false);
        setFilterMenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Fetch data whenever filters change, excluding the initial render
  useEffect(() => {
    if (!initialRender.current) {
      const filterParams = {};

      // Add only true filters to the params
      Object.keys(filters).forEach((key) => {
        if (
          filters[key] &&
          ['veg', 'nonVeg', 'bestsellers', 'goodRatings'].includes(key)
        ) {
          // Map state keys to API parameter names if necessary
          filterParams[key + 'Toggle'] = filters[key];
        }
      });

      // Handle sorting separately
      if (filters.lowToHigh) {
        filterParams.sort = 'lowToHigh';
      } else if (filters.highToLow) {
        filterParams.sort = 'highToLow';
      }

      fetchData(filterParams);
    } else {
      initialRender.current = false; // Skip fetch on initial render
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const dispatch = useDispatch();
  // Function to fetch data based on filters
  async function fetchData(filterParams) {
    if (Object.keys(filterParams).length !== 0) {
      try {
        const response = await generateGetCall({
          apiPath: API_ENDPOINT_NAME.GET_ADMIN_CATEGORY,
          additionalData: filterParams,
        });

        if (response.success) {
          // setcategoryList(response.response.data.categoriesWithDishes);
          dispatch(
            storeAllCategory(response.response.data.categoriesWithDishes)
          );
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error appropriately
      }
    } else {
      // Optionally, handle the case where no filters are applied
      // For example, fetch all categories without filters
      try {
        const response = await generateGetCall({
          apiPath: API_ENDPOINT_NAME.GET_ADMIN_CATEGORY,
        });

        if (response.success) {
          // setcategoryList(response.response.data.categoriesWithDishes);
          dispatch(
            storeAllCategory(response.response.data.categoriesWithDishes)
          );
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error appropriately
      }
    }
  }

  // Generic toggle function for filters
  const toggleFilter = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  // Handle applying sorting options
  const handleApplySorting = (e) => {
    e.stopPropagation();
    setSortMenu(false);
    // Sorting is handled via the useEffect hook
  };

  // Handle applying filtering options
  const handleApplyFiltering = (e) => {
    e.stopPropagation();
    setFilterMenu(false);
    // Filtering is handled via the useEffect hook
  };

  // Define filter options for rendering
  const readyToAddFilter = [
    {
      name: 'Veg',
      id: 'veg',
      icon: VegItem,
    },
    {
      name: 'Non-Veg',
      id: 'nonVeg',
      icon: NonVegItem,
    },
    {
      name: 'Bestsellers',
      id: 'bestsellers',
      icon: bestsellersItem,
    },
  ];

  return (
    <div className={styles.menuIndex_main_wrapper}>
      <header>
        <h3>Menu</h3>
        {/* <div className={styles.filters}>
          <div
            className={`${styles.filter_box} ${styles.desktopFilter}`}
            onClick={() => setFilterMenu((prev) => !prev)}
          >
            <SortingIcon className={styles.primaryColorFill} />
            <span>Filters</span>
            <DownArrow
              className={`${styles.primaryColorStroke} ${styles.downArrow}`}
            />
          </div>

          <div
            className={`${styles.filter_box} ${styles.mobileFilter}`}
            onClick={() => setFilterMenu((prev) => !prev)}
          >
            <FilterIcon className={styles.primaryColorFill} />
          </div>
        </div> */}

        <div className={styles.header_partOne}>
          {/* Render Filter Options */}
          <div className={styles.readyToAddFiler}>
            {readyToAddFilter.map((filter) => (
              <span
                key={filter.id}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFilter(filter.id);
                }}
                className={styles.single_filter_box}
              >
                {filters[filter.id] && (
                  <div className={styles.filterBackground}></div>
                )}

                {typeof filter.icon === 'string' ? (
                  <img src={filter.icon} alt={filter.name} loading="lazy" />
                ) : (
                  <filter.icon className={styles.primaryColorFill} />
                )}

                <span>{filter.name}</span>
                {filters[filter.id] && (
                  <CloseIcon
                    className={`${styles.cursorPointer} ${styles.closeIcon}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        [filter.id]: false,
                      }));
                    }}
                  />
                )}
              </span>
            ))}
          </div>
        </div>

        {/* Sorting and Filtering Menus */}
        {/* <div className={styles.header_partOne}>
          <span onClick={() => setSortMenu((prev) => !prev)}>
            <SortingIcon className={styles.primaryColorFill} />
            {sortMenu && (
              <ul className={styles.filterList} ref={menuRef}>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sort"
                      checked={filters.lowToHigh}
                      onChange={() => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          lowToHigh: true,
                          highToLow: false,
                        }));
                      }}
                    />
                    Price (Low to High)
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sort"
                      checked={filters.highToLow}
                      onChange={() => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          highToLow: true,
                          lowToHigh: false,
                        }));
                      }}
                    />
                    Price (High to Low)
                  </label>
                </li>
                <li>
                  <button onClick={handleApplySorting}>Apply</button>
                </li>
              </ul>
            )}
            Sort
          </span>

          <span onClick={() => setFilterMenu((prev) => !prev)}>
            <FilterIcon className={styles.primaryColorFill} /> Filter
            {filterMenu && (
              <ul className={styles.sortingList} ref={menuRef}>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={filters.goodRatings}
                      onChange={() => toggleFilter('goodRatings')}
                    />
                    Good Ratings
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={filters.bestsellers}
                      onChange={() => toggleFilter('bestsellers')}
                    />
                    Bestsellers
                  </label>
                </li>
                <li>
                  <button onClick={handleApplyFiltering}>Apply</button>
                </li>
              </ul>
            )}
          </span>
        </div> */}
      </header>
    </div>
  );
};

export default FilterMenuIndex;
