import React, { useEffect, useState } from 'react';
import styles from '../../styles/employee/employeeCard.module.scss';
import { ReactComponent as Ellipses } from '../../assets/employee/ellipses.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from '../../assets/admin/profile.svg';
import { getFileData } from '../../api_calls/getFileData';
import { API_ENDPOINT_NAME } from '../../utils/constants';

const style = {
  gap: '0.4rem',
  flexDirection: 'column',
};

const styleSingle = {
  marginTop: '0.5rem',
};

const DataDisplay = ({ name, data, style }) => {
  return (
    <>
      <div className={styles.data_display_wrapper} style={style}>
        <span>{name}</span>
        <span>{data}</span>
      </div>
    </>
  );
};

const EmployeeCard = ({ item, setSelectedProfile }) => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState('');

  const setProfilePic = async () => {
    try {
      let responseBlob = await getFileData({
        apiPath: API_ENDPOINT_NAME.DOWNLOAD_EMPLYOEE_ASSET,
        data: {
          userId: item?._id,
          assetId: item?.userImg?.assetId,
          assetType: item?.userImg?.fieldName,
        },
      });

      if (responseBlob) {
        let url = URL.createObjectURL(responseBlob.fileBlob);
        setProfileImage(url);
      }
    } catch (error) {
      console.error('Error setting profile pic:', error);
    }
  };

  const handleClick = () => {
    setSelectedProfile(item);
    // setAddEmp(true);
    // navigate(item?._id);
    navigate('/admin/employee/' + item._id);
  };

  useEffect(() => {
    setProfilePic();
  }, []);

  return (
    <>
      <div className={styles.EmployeeCard_wrapper} onClick={handleClick}>
        <header>
          <span
            className={
              item.active ? styles.employee_active : styles.employee_not_active
            }
          >
            {item.active ? 'Active' : 'Not Active'}
          </span>
          <span>
            {/* <img src={ellipses} alt="ellipses" /> */}
            <Ellipses className={styles.primaryColorFill} />
          </span>
        </header>
        <div className={styles.profile_pic_wrapper}>
          {profileImage ? (
            <img
              src={profileImage}
              alt="profile"
              className={styles.profile_pic}
            />
          ) : (
            <ProfileIcon className={styles.primaryColorFill} />
          )}
          <span>{item.name}</span>
          <span>{item.role}</span>
        </div>
        <hr className={styles.card_seprator}></hr>
        <div className={styles.employee_details_wrapper}>
          <div className={styles.initial_wrapper}>
            <DataDisplay name="Role" data={item.role} style={style} />
            <DataDisplay
              name="Date Joined"
              data={item.dateJoined}
              style={style}
            />
          </div>
          <DataDisplay name="Mobile" data={item.number} style={styleSingle} />
          <DataDisplay name="Email" data={item.email} style={styleSingle} />
        </div>
        <div className={styles._last_login_wrapper}>
          Last Login : {item.lastLogin}
        </div>
      </div>
    </>
  );
};

export default EmployeeCard;
