import { createSlice } from '@reduxjs/toolkit';
import { original } from '@reduxjs/toolkit';
const initialState = {
  category: [],
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    storeAllCategory: (state, action) => {
      // console.log(action.payload);
      state.category = action.payload;
    },
    deleteCategoryReducer: (state, action) => {
      state.category = state.category.filter(
        (category) => category._id !== action.payload
      );
    },
    deleteDishFromCategory: (state, action) => {
      const { categoryIds, _id } = action.payload;

      categoryIds?.forEach((categoryId) => {
        const categoryIndex = state.category.findIndex(
          (category) => category._id === categoryId
        );
        if (categoryIndex !== -1) {
          state.category[categoryIndex].dishes = state.category[
            categoryIndex
          ].dishes.filter((dish) => dish._id !== _id);
        }
      });
    },
    updateDishInCategory: (state, action) => {
      const { categoryId, _id, updatedDish } = action.payload;
      const categoryIndex = state.category.findIndex(
        (category) => category._id === categoryId
      );
      if (categoryIndex !== -1) {
        const dishIndex = state.category[categoryIndex].dishes.findIndex(
          (dish) => dish._id === _id
        );
        if (dishIndex !== -1) {
          state.category[categoryIndex].dishes[dishIndex] = {
            ...state.category[categoryIndex].dishes[dishIndex],
            ...updatedDish,
          };
        }
      }
      // Update logic...
    },
    updateCategory: (state, action) => {
      const updatedCategory = action.payload;
      const categoryIndex = state.category.findIndex(
        (category) => category._id === updatedCategory._id
      );
      if (categoryIndex !== -1) {
        state.category[categoryIndex] = updatedCategory;
      } else {
        state.category.push(updatedCategory);
      }
    },
    editDishAvaiablity: (state, action) => {
      const { categoryId, dishId, available } = action.payload;
      categoryId?.forEach((categoryId) => {
        const categoryIndex = state.category.findIndex(
          (category) => category._id === categoryId
        );
        if (categoryIndex !== -1) {
          const dishIndex = state.category[categoryIndex].dishes.findIndex(
            (dish) => dish._id === dishId
          );
          if (dishIndex !== -1) {
            state.category[categoryIndex].dishes[dishIndex] = {
              ...state.category[categoryIndex].dishes[dishIndex],
              available: available,
            };
          }
        }
      });
    },
  },
});

export const {
  storeAllCategory,
  deleteCategoryReducer,
  deleteDishFromCategory,
  updateCategory,
  updateDishInCategory,
  editDishAvaiablity,
} = categorySlice.actions;

export const getAllCategories = (state) => state.category.category;

export default categorySlice.reducer;
